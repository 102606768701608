import React, { useContext } from "react";
import PaystackPop from "@paystack/inline-js";
import { useState } from "react";
import { useAlert } from "react-alert";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { AuthContext } from "../helpers/AuthContext";
import { useEffect } from "react";
import {
  addQuotation,
  getIndividual,
  makePayment,
  addStatus,
  addPaidCount,
} from "../services";
import { notify, numFormat } from "../Components/CommonActions";
import { IndividualCreditNote } from "../Components/IndividualCreditNote";

export const PaystackIntegration = () => {
  const [status, setStatus] = useState({});
  const [timer, setTimer] = useState(false);
  const {
    isPayment,
    setIsPayment,
    setIsFullProcess,
    userData,
    isIndividualCreditNotePay,
    setIsIndividualCreditNotePay,
  } = useContext(AuthContext);
  const alert = useAlert();
  const [individualVehicle, setIndividualVehicle] = useState([]);
  const [pay, setPay] = useState({
    email: "",
    amount: "",
    firstName: "",
    lastName: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPay((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  let customerId = sessionStorage.getItem(process.env.REACT_APP_ID);

  const { data, refetch, isSuccess } = useQuery(
    "curIndividual",
    () => getIndividual(customerId)
    // {
    //   onError: (error) => {
    //     console.log(error);
    //   },
    // }
  );

  const brokerDetails = localStorage.getItem(process.env.REACT_APP_USER_DATA);
  const brokerId = JSON.parse(brokerDetails);

  const details = sessionStorage.getItem(process.env.REACT_APP_PLAY);
  const transactionDetails = JSON.parse(details);

  const clientId = sessionStorage.getItem(process.env.REACT_APP_ID);

  const handlePay = () => {
    const paystack = new PaystackPop();
    paystack.newTransaction({
      key: "pk_test_880e2768f5e8d49b86590937fd5d1eb4732606db",
      amount: transactionDetails && transactionDetails.payablePremium * 100,
      email: data && data[0].email,
      firstname: data && data[0].firstName,
      lastname: data && data[0].lastName,
      onSuccess: async (transaction) => {
        let updateType;
        updateType = sessionStorage.getItem(process.env.REACT_APP_IS_UPDATE);
        newPayment(transaction.reference);
        updateType === true ? updatePayment() : newStatus();
        updateType === true ? updatePaidCount() : newPaidCount();
      },
      onCancel() {
        alert("Transaction Canceled");
      },
    });
  };

  let vid = sessionStorage.getItem(process.env.REACT_APP_VEHICLE_ID);

  const updatePayment = async () => {
    try {
      const update = await fetch(
        `${process.env.REACT_APP_API_URL}vehicle/update/${vid}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      ).then((res) => res.json());
      notify("success", update.title, update.message);
    } catch (error) {
      console.log(error);
    }
  };
  const updatePaidCount = async () => {
    try {
      const update = await fetch(
        `${process.env.REACT_APP_API_URL}vehicle/update-paid-count/${vid}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      ).then((res) => res.json());
      notify("success", update.title, update.message);
    } catch (error) {
      console.log(error);
    }
  };
  const newStatus = async () => {
    await addStatus(transactionDetails && transactionDetails.RegNo)
      .then((res) => {
        notify("success", "success", "Payment Successful");
      })
      .catch((error) => notify("danger", "Error", "Something went wrong"));
  };
  const newPaidCount = async () => {
    await addPaidCount(transactionDetails && transactionDetails.RegNo)
      .then((res) => {
        console.log("+");
      })
      .catch((error) => notify("danger", "Error", "Something went wrong"));
  };
  let navigate = useNavigate();

  const newPayment = async (tid) => {
    makePayment(tid, transactionDetails && transactionDetails.RegNo).catch(
      (error) => {
        console.log(error);
      }
    );
    addQuotation({
      clientId: clientId && clientId,
      brokerCode: userData.brokerCode,
      fleetCode: null,
      regNumber: transactionDetails && transactionDetails.RegNo,
      SumInsured: transactionDetails && transactionDetails.VehicleValue,
      Rate: transactionDetails && transactionDetails.Rate,
      Premium: transactionDetails && transactionDetails.totalPremium,
      Duration: transactionDetails && transactionDetails.duration,
      PayablePremium: transactionDetails && transactionDetails.payablePremium,
      Status: "PAID",
    })
      .then(() => {
        window.location.reload(false);
      })
      .catch((error) =>
        notify("danger", "Error", "Error: Something went wrong")
      );
  };

  return (
    <div
      className={
        isPayment ? "pmt-container show-pmt-container" : "pmt-container"
      }
    >
      <div className="customer-information pmt-information">
        <div className="customer-information-header">
          <h1 className="header">CHECKOUT</h1>
          <IoCloseOutline
            className="btn-close"
            onClick={() => setIsPayment(false)}
          />
        </div>

        {/* {data && data.length > 0 && (
          <>
            <div className="payout-top">
              <div>
                <span>First Name</span>
                <p>{data && data[0].firstName}</p>
              </div>
              <div>
                <span>Last Name</span>
                <p>{data && data[0].lastName}</p>
              </div>
            </div>
            <div className="email-div">
              <span>Email</span>
              <p>{data && data[0].email}</p>
            </div>
          </>
        )} */}

        <div className="payout-bottom">
          {transactionDetails.VehicleValue !== "" && (
            <div>
              <span>SUM INSURED</span>
              <p>
                ₦‎
                {numFormat(
                  transactionDetails && transactionDetails.VehicleValue
                )}
              </p>
            </div>
          )}
          {transactionDetails.Rate !== "" && (
            <div>
              <span>Rate</span>
              <p>{transactionDetails && transactionDetails.Rate}%</p>
            </div>
          )}
          <div>
            <span>TOTAL PREMIUM</span>
            <p>
              ₦‎
              {numFormat(transactionDetails && transactionDetails.totalPremium)}
            </p>
          </div>
        </div>
        <div className="payout-bottom">
          <div>
            <span>FREQUENCY OF PAYMENT</span>
            <p>
              {transactionDetails && transactionDetails.duration === "1"
                ? "One Off (Yearly)"
                : transactionDetails && transactionDetails.duration === "2"
                ? "Bi-annual"
                : "Quarterly"}
            </p>
          </div>
          {/* <div>
            <span>Rate</span>
            <p>{transactionDetails && transactionDetails.Rate}%</p>
          </div> */}
          <div>
            <span>PAYABLE PREMIUM</span>
            <p>
              ₦‎
              {numFormat(
                transactionDetails && transactionDetails.payablePremium
              )}
            </p>
          </div>
        </div>

        <div className="amount-due">
          <p>Amount Due:</p>
          <h2>
            ₦‎
            {numFormat(transactionDetails && transactionDetails.payablePremium)}
          </h2>
        </div>

        <h6 style={{ marginBottom: "10px", marginTop: "20px" }}>Pay with</h6>
        <div className="pay-btn-container">
          <button className="btn-next" onClick={() => handlePay()}>
            Card / Bank Account
          </button>
          <button
            className="btn-next"
            onClick={() => {
              setIsIndividualCreditNotePay(true);
              console.log(isIndividualCreditNotePay);
            }}
          >
            Credit note
          </button>
        </div>

        {timer && (
          <>
            <h1>{status.Title}</h1>
            <p>{status.message}</p>
            <span>You would be redirected now...</span>
          </>
        )}
      </div>
      {isIndividualCreditNotePay && <IndividualCreditNote />}
    </div>
  );
};
