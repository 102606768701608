import React, { useEffect, useState } from "react";

export const Search = () => {
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [name, setName] = useState("");
  const [occupation, setOccupation] = useState("");

  const handleSerach = () => {
    const newData = data
      .filter((x) => x.name == (name == "" ? x.name : name))
      .filter(
        (y) => y.occupation == (occupation == "" ? y.occupation : occupation)
      );
    setSearchData(newData);
  };

  useEffect(() => {
    const data = [
      { name: "Efe", age: "24", occupation: "developr" },
      { name: "Fortune", age: "24", occupation: "Analyst" },
      { name: "Samuel", age: "24", occupation: "Engineer" },
      { name: "Job", age: "24", occupation: "Engineer" },
      { name: "Jacob", age: "24", occupation: "pastor" },
      { name: "Genius", age: "24", occupation: "doctor" },
    ];

    setData(data);
    setSearchData(data);
  }, []);
  return (
    <div>
      <table>
        <tr>
          <td>
            <input
              placeholder="Enter Name"
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
          </td>
          <td>
            <select onChange={(e) => setOccupation(e.target.value)}>
              <option>-Select-</option>
              <option value="developer">developer</option>
              <option value="Analyst">Analyst</option>
              <option value="Engineer">Engineer</option>
              <option value="pastor">Pastor</option>
              <option value="doctor">doctor</option>
            </select>
          </td>
          <td>
            <button onClick={() => handleSerach()}>Search</button>
          </td>
        </tr>
      </table>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Age</th>
            <th>occupation</th>
          </tr>
        </thead>
        <tbody>
          {searchData && searchData.length > 0
            ? searchData.map((item) => (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.age}</td>
                  <td>{item.occupation}</td>
                </tr>
              ))
            : "No data"}
        </tbody>
      </table>
    </div>
  );
};
