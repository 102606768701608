import React from "react";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  customDataMessage,
  noDataMessage,
  paginate,
} from "../Components/CommonActions";
import { getMyCorporates } from "../services";
import CustomerInformation from "./CustomerInformation";
import CryptoJS from "crypto-js";
import CorporateInformation from "./CorporateInformation";

const CorporatesPage = () => {
  const [isCustomer, setIsCustomer] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [corporateOptions, setCorporateOptions] = useState(0);
  const [search, setSearch] = useState("");

  const { data, status, isSuccess, refetch } = useQuery(
    "corporates",
    getMyCorporates,
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const queryClient = useQueryClient();

  let navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;

  const searchData = search
    ? data.filter((item) =>
        (
          item.name.toLowerCase() +
          item.email.toLowerCase() +
          item.phone.toLowerCase() +
          item.agreedRate.toLowerCase() +
          item.natureOfBusiness.toLowerCase()
        ).includes(search.toLowerCase())
      )
    : data;

  const displayCorporates =
    searchData &&
    searchData
      .slice(pagesVisited, pagesVisited + usersPerPage)
      .map((corporate) => {
        return (
          <tr
            key={corporate.id}
            onClick={() => {
              sessionStorage.setItem(
                process.env.REACT_APP_CORPORATE_RATE,
                corporate.agreedRate
              );
              sessionStorage.setItem(
                process.env.REACT_APP_CORPORATE_ID,
                corporate.name
              );
              sessionStorage.setItem(
                "thirdPartyPremium",
                corporate.thirdPartyPremium
              );
              sessionStorage.setItem(
                "privatePremium",
                corporate.privatePremium
              );
              sessionStorage.setItem(
                "commercialPremium",
                corporate.commercialPremium
              );
              sessionStorage.setItem(
                "motorcyclePremium",
                corporate.motorcyclePremium
              );
              // sessionStorage.setItem("id", individual.id)
            }}
          >
            <td>{corporate.name}</td>
            <td>{corporate.email}</td>
            <td className="phone-td">{corporate.phone}</td>
            <td>{corporate.agreedRate}</td>
            <td>{corporate.natureOfBusiness}</td>
            <td
              className="dots"
              onClick={() => {
                corporateOptions === corporate.id
                  ? setCorporateOptions(0)
                  : setCorporateOptions(corporate.id);
                sessionStorage.setItem(
                  process.env.REACT_APP_CORPORATE_FLEET_NAME,
                  corporate.name
                );
              }}
            >
              ...
            </td>
            <td
              className={
                corporate.id === corporateOptions
                  ? "vehicle-options show-vehicle-options"
                  : "vehicle-options"
              }
            >
              <ul>
                <li
                  onClick={() => {
                    const encryptedId = CryptoJS.AES.encrypt(
                      JSON.stringify(corporate.id),
                      process.env.REACT_APP_CRYPTO_SECRET
                    ).toString();

                    const excludedChars = /[/]/g;
                    const filteredString = encryptedId.replace(
                      excludedChars,
                      "0GCgC2zO3Der7YlxA67s"
                    );

                    navigate(`/corporates/${filteredString}`);

                    // sessionStorage.setItem("id", individual.id)
                  }}
                >
                  View Fleets
                </li>
              </ul>
            </td>
          </tr>
        );
      });

  const pageCount = Math.ceil(data && data.length / usersPerPage);
  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard">
        <div className="dashboard-header">
          <div>
            <h1>Corporates</h1>
            <p>{`${isSuccess && searchData.length} corporate clients`}</p>
          </div>
          {/* <div className="dashboard-button-container"> */}
          <button
            onClick={() => {
              setIsClient(!isClient);
              // setIsEdit(false);
            }}
          >
            + Add Client
          </button>
          {/* </div> */}
        </div>
        {data && data.length < 1 ? (
          customDataMessage(
            "Corporate Client",
            "Contact an administrator to add a Corporate Client..."
          )
        ) : (
          <>
            {data && (
              <div className="search-container">
                <input
                  placeholder="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {/* {exportCsv(
                data,
                headers,
                `Individual clients ${new Date(Date.now())}`
              )} */}
              </div>
            )}
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>email</th>
                  <th>Phone</th>
                  <th>Rate %</th>
                  <th>Nature Of Business</th>
                  <th className="dots">...</th>
                </tr>
              </thead>
              <tbody>
                {status === "loading" ? (
                  <div>loading</div>
                ) : status === "error" ? (
                  <div>Error</div>
                ) : (
                  <>{displayCorporates}</>
                )}
              </tbody>
            </table>
          </>
        )}
        {paginate(pageCount, handlePageClick)}
      </div>
      <CustomerInformation
        isCustomer={isCustomer}
        setIsCustomer={setIsCustomer}
      />
      <CorporateInformation
        isClient={isClient}
        setIsClient={setIsClient}
        refetch={refetch}
        // setEditId={setEditId}
        queryClient={queryClient}
      />
    </div>
  );
};
export default CorporatesPage;
