import * as React from "react";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = process.env.REACT_APP_TIMEOUT;
//axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post["Accept"] = "application/json";
//axios.defaults.headers.common.accept = 'application/json';

axios.interceptors.request.use(
  async (config) => {
    const token =
      localStorage.getItem(process.env.REACT_APP_USER_TOKEN) || null;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function loginBroker(values) {
  const { data } = await axios.post(`broker/login-broker`, values);
  return data;
}
export async function loginAdmin(values) {
  const { data } = await axios.post(`admin/login-admin`, values);
  return data;
}
export async function updateBrokerPassword(values, brokerCode) {
  const { data } = await axios.post(
    `broker/update-password/${brokerCode}`,
    values
  );
  return "Password updated successfully";
}
export async function getAllNotes() {
  const { data } = await axios.get(`notes/get-creditnotes`);
  return data;
}
export async function addStatus(RegNo) {
  const { data } = await axios.get(`vehicle/status/${RegNo}`);
  return "status updated successfully";
}

export const updateCertNum = async () => {
  const { data } = await axios.put(`vehicle/update-cert-num`);
  return data;
};

export async function newCustomer(values) {
  const { data } = await axios.post(`corporate/create-corporate`, values);
  return "Customer added successfully";
}

export async function addPaidCount(RegNo) {
  const { data } = await axios.get(`vehicle/paid-count/${RegNo}`);
  return "paid count updated successfully";
}
export async function getIndividuals() {
  const { data } = await axios.get(`individual/get-myindividuals`);
  return data;
}
export async function getIndividual(customerId) {
  const { data } = await axios.get(`individual/get-individual/${customerId}`);
  return data;
}
export async function getIndividualInfo(id) {
  const { data } = await axios.get(`individual/${id}`);
  return data;
}
export async function makePayment(tid, RegNo) {
  const { data } = await axios.get(`payment/create-payment/${tid}/${RegNo}`);
  return data;
}
export async function getIndividualVehicles(id) {
  const { data } = await axios.get(`vehicle/${id}`);
  return data;
}
export async function deleteVehicle(deleteId) {
  const { data } = await axios.delete(`vehicle/delete/${deleteId}`);
  return "Customer Deleted";
}
export async function deleteFleet(deleteCode) {
  const { data } = await axios.delete(`fleet/delete/${deleteCode}`);
  return data;
}
export async function deleteIndividual(individualId) {
  const { data } = await axios.delete(`individual/delete/${individualId}`);
  return data;
}
export async function createIndividual(values) {
  const { data } = await axios.post(`individual/create-individual`, values);
  return "Customer added successfully";
}
export async function createVehicle(values) {
  const { data } = await axios.post(`vehicle/create-vehicle`, values);
  return "Created Successfully";
}
export async function newFleetVehicles(values) {
  const { data } = await axios.post(`vehicle/create-fleet`, values);
  return data;
}
export async function newFleet(values) {
  const { data } = await axios.post(`fleet/new-fleet`, values);
  return data;
}
export async function updateIndividual(values, individualId) {
  const { data } = await axios.post(
    `/individual/update/${individualId}`,
    values
  );
  return "update successful!";
}
export async function getMyCorporates() {
  const { data } = await axios.get(`corporate/get-mycorporates`);
  return data.data;
}
export async function getMyCorporate(id) {
  const { data } = await axios.get(`corporate/get-corporate/${id}`);
  return data;
}
export async function getFleets(id) {
  const { data } = await axios.get(`fleet/get-fleets/${id}`);
  return data;
}
export async function getMyQuotations(regNo) {
  const { data } = await axios.get(`quotation/get-myquotations/${regNo}`);
  return data;
}
export async function getCorporateQuotations(fleetCode) {
  const { data } = await axios.get(
    `quotation/get-corporatequotations/${fleetCode}`
  );
  return data;
}
export async function getFleetVehicles(fleetCode) {
  const { data } = await axios.get(`vehicle/get-fleet-vehicles/${fleetCode}`);
  return data;
}
export async function getLovDetails() {
  const { data } = await axios.get(`lov`);
  return data;
}
export async function addQuotation(values) {
  const { data } = await axios.post(`quotation/add-quotation`, values);
  return data;
}

export async function forgotPassword(values) {
  const { data } = await axios.post(`auth/forgot-password`, values);
  return data;
}

export async function resetPassword(values) {
  const { data } = await axios.post(`auth/reset-password`, values);
  return data;
}

/* USER */
export async function userUpdate(values) {
  const { data } = await axios.post(`profile/update`, values);
  return data;
}

/* Category */
export const categories = async () => {
  const { data } = await axios.get(`categories`);
  return data;
};

export const categoriesList = async () => {
  const { data } = await axios.get(`categories/list`);
  return data;
};

export const categoryAdd = async (values) => {
  const { data } = await axios.post(`categories`, values);
  return data;
};

export const categoryEdit = async ({ id, values }) => {
  const { data } = await axios.put(`categories/${id}`, values);
  return data;
};

export const categoryDelete = async (id) => {
  const { data } = await axios.delete(`categories/${id}`);
  return data;
};

export const categorySort = async (value) => {
  const { data } = await axios.post(`categories/sort`, value);
  return data;
};

/* Menu */
export const menus = async () => {
  const { data } = await axios.get(`menus`);
  return data;
};

export const menuAdd = async (values) => {
  const { data } = await axios.post(`menus`, values);
  return data;
};

export const menuEdit = async ({ id, values }) => {
  const { data } = await axios.put(`menus/${id}`, values);
  return data;
};

export const menuDelete = async (id) => {
  const { data } = await axios.delete(`menus/${id}`);
  return data;
};
