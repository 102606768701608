import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Certificate } from "../Components/Certificate";
import { format } from "date-fns";
import { AuthContext } from "../helpers/AuthContext";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import CertificateImage from "../Photos/CertificateImg.png";
import { getIndividualVehicles, getLovDetails } from "../services";
import { IoCloseOutline } from "react-icons/io5";
import { numFormat } from "../Components/CommonActions";

export const Vehicle = ({
  showDetail,
  setShowDetail,
  vehicleDetails,
  setVehicleDetails,
}) => {
  const { isPayment, setIsPayment } = useContext(AuthContext);
  const [individual, setIndividual] = useState([]);
  // const [details, setDetails] = useState([]);
  // let { id } = useParams();

  let id = sessionStorage.getItem(process.env.REACT_APP_VEHICLE_ID);

  const { data, status } = useQuery(
    "vehicle",
    () => getIndividualVehicles(id),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { data: details, status: detailStatus } = useQuery(
    "cor-det",
    getLovDetails
  );

  const corporatePayment = sessionStorage.getItem(
    process.env.REACT_APP_CORPORATE_PAYMENT
  );

  const getInfo = (Item, type) => {
    let make;
    let makeCode;
    if (details) {
      make = details.find(
        (code) => code.pc_code === Item && code.pc_type === type
      );
      makeCode = make && make.pc_desc;
    }
    return makeCode;
  };

  const transactionDetails = {
    VehicleValue: data && data[0].VehicleValue,
    sumInsured: data && (data[0].Rate / 100) * data[0].VehicleValue,
    Rate: data && data[0].Rate,
    RegNo: data && data[0].RegNo,
  };
  const newDetails = JSON.stringify(transactionDetails);

  status === "success" &&
    sessionStorage.setItem(process.env.REACT_APP_PLAY, newDetails);

  let customerName = sessionStorage.getItem(
    process.env.REACT_APP_CUSTOMER_NAME
  );

  return (
    <div
      className={
        vehicleDetails
          ? "customer-container show-customer-container"
          : "customer-container"
      }
    >
      <div className="dashboard-container">
        <div className="dashboard">
          {data &&
            data.map((vehicle) => {
              const {
                brokerId,
                chasisNumber,
                clientId,
                corporateId,
                TypeOfcover,
                createdAt,
                duration,
                engineNumber,
                id,
                MfgYear,
                UsageType,
                RegNo,
                BodyType,
                sumInsured,
                Colour,
                Make,
                Model,
                PlaceOfRegn,
                CC,
                Rate,
                VehicleValue,
                CoverCode,
                VehEntryDt,
                VehExitDt,
                status,
              } = vehicle;

              return (
                <div key={id}>
                  <div className="vehicle-container">
                    <div className="customer-information car-details">
                      <div className="customer-information-header">
                        <h1 onClick={() => setShowDetail(false)}>
                          {getInfo(Make, "MOT_VEH_MAKE")}{" "}
                          {getInfo(Model, "VEHI_MOD")}
                        </h1>
                        {/* {status === "Paid" && (
                          <PDFDownloadLink
                            document={
                              <Document>
                                <Page size="A4" style={styles.page}>
                                  <Text style={styles.text}>
                                    {customerName}
                                  </Text>
                                  <Text style={styles.make}>
                                    {" "}
                                    {`Make:     ${getInfo(
                                      Make,
                                      "MOT_VEH_MAKE"
                                    )}`}
                                  </Text>
                                  <Text style={styles.coverType}>
                                    {getInfo(TypeOfcover, "COVER_TYPE")}
                                  </Text>
                                  <Text style={styles.regNo}>{RegNo}</Text>
                                  <Text style={styles.entryDate}>
                                    {format(new Date(VehEntryDt), "MM/dd/yyyy")}
                                  </Text>
                                  <Text style={styles.exitDate}>
                                    {format(new Date(VehExitDt), "MM/dd/yyyy")}
                                  </Text>
                                  <Image
                                    src={CertificateImage}
                                    style={styles.image}
                                  />
                                </Page>
                              </Document>
                            }
                            fileName="certificate"
                          >
                            {({ loading }) => (
                              <button className="generate-btn">
                                {loading
                                  ? "please wait..."
                                  : "Generate certificate"}
                              </button>
                            )}
                          </PDFDownloadLink>
                        )} */}

                        {status === "Not Paid" && (
                          <button
                            className={corporatePayment ? "hide-btn" : ""}
                            onClick={() => {
                              setIsPayment(true);
                              sessionStorage.setItem(
                                process.env.REACT_APP_IS_UPDATE,
                                true
                              );
                            }}
                          >
                            Make Payment
                          </button>
                        )}
                        <IoCloseOutline
                          className="btn-close"
                          onClick={() => setVehicleDetails(false)}
                        />
                      </div>
                      <div className="double-input">
                        <div className="reg">
                          <label htmlFor="regNumber">ENTRY DATE</label>
                          <input
                            placeholder="REGISTRATION NUMBER"
                            name="regNumber"
                            value={format(new Date(VehEntryDt), "MM/dd/yyyy")}
                            onChange={(e) => e.target.value}
                            readOnly
                            disabled
                          />
                        </div>
                        <div className="reg">
                          <label htmlFor="regNumber">EXIT DATE</label>
                          <input
                            placeholder="REGISTRATION NUMBER"
                            name="regNumber"
                            value={format(new Date(VehExitDt), "MM/dd/yyyy")}
                            onChange={(e) => e.target.value}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                      <div className="double-input">
                        <div className="reg">
                          <label htmlFor="regNumber">REGISTRATION NUMBER</label>
                          <input
                            placeholder="REGISTRATION NUMBER"
                            name="regNumber"
                            value={RegNo}
                            onChange={(e) => e.target.value}
                            readOnly
                            disabled
                          />
                        </div>
                        <div className="reg">
                          <label htmlFor="regNumber">
                            PLACE OF REGISTRATION
                          </label>
                          <input
                            placeholder="REGISTRATION NUMBER"
                            name="regNumber"
                            value={getInfo(PlaceOfRegn, "LOCATION")}
                            onChange={(e) => e.target.value}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                      <div className="triple-input">
                        <div className="reg">
                          <label htmlFor="vehicleModel">VEHICLE MODEL</label>
                          <input
                            placeholder="VEHICLE MODEL"
                            name="vehicleModel"
                            readOnly
                            disabled
                            value={getInfo(Model, "VEHI_MOD")}
                            onChange={(e) => e.target.value}
                          />
                        </div>
                        <div className="reg">
                          <label htmlFor="vehicleMake">VEHICLE MAKE</label>
                          <input
                            placeholder="VEHICLE MAKE"
                            name="vehicleMake"
                            readOnly
                            disabled
                            value={getInfo(Make, "MOT_VEH_MAKE")}
                            onChange={(e) => e.target.value}
                          />
                        </div>
                        <div className="reg">
                          <label htmlFor="makeYear">MAKE YEAR</label>
                          <input
                            placeholder="MAKE YEAR"
                            name="makeYear"
                            readOnly
                            disabled
                            value={MfgYear}
                            onChange={(e) => e.target.value}
                          />
                        </div>
                      </div>
                      <div className="triple-input">
                        <div className="reg">
                          <label htmlFor="engineNumber">ENGINE NUMBER</label>
                          <input
                            placeholder="ENGINE NUMBER"
                            name="engineNumber"
                            readOnly
                            disabled
                            value={engineNumber}
                            onChange={(e) => e.target.value}
                          />
                        </div>
                        <div className="reg">
                          <label htmlFor="chasisNumber">CHASIS NUMBER</label>
                          <input
                            placeholder="CHASIS NUMBER"
                            name="chasisNumber"
                            readOnly
                            disabled
                            value={chasisNumber}
                            onChange={(e) => e.target.value}
                          />
                        </div>
                        <div className="reg">
                          <label htmlFor="vehicleColor">VEHICLE COLOR</label>
                          <input
                            placeholder="VEHICLE COLOR"
                            name="vehicleColor"
                            readOnly
                            disabled
                            value={getInfo(Colour, "COLOR")}
                            onChange={(e) => e.target.value}
                          />
                        </div>
                      </div>
                      <div className="triple-input">
                        <div className="reg">
                          <label htmlFor="purchaseDate">USAGE TYPE</label>
                          <input
                            placeholder="PURCHASE DATE"
                            name="purchaseDate"
                            readOnly
                            disabled
                            value={getInfo(UsageType, "VEH_TYPE")}
                            onChange={(e) => e.target.value}
                          />
                        </div>
                        <div className="reg">
                          <label htmlFor="startDate">BODY TYPE</label>
                          <input
                            placeholder="START DATE"
                            name="startDate"
                            readOnly
                            disabled
                            value={getInfo(BodyType, "MOT_BODY_TYP")}
                            onChange={(e) => e.target.value}
                          />
                        </div>
                        {/* <div className="reg">
                          <label htmlFor="duration">Cover Code</label>
                          <input
                            placeholder="DURATION"
                            name="duration"
                            readOnly
                            disabled
                            value={getInfo(CoverCode, "COVER_CODE")}
                            onChange={(e) => e.target.value}
                          />
                        </div> */}
                      </div>
                      <div className="double-input">
                        <div className="reg">
                          <label htmlFor="coverType">COVER TYPE</label>
                          <input
                            placeholder="TYPE OF COVER"
                            name="coverType"
                            readOnly
                            disabled
                            value={getInfo(TypeOfcover, "COVER_TYPE")}
                            onChange={(e) => e.target.value}
                          />
                        </div>
                        <div className="reg">
                          <label htmlFor="coverType">CC</label>
                          <input
                            placeholder="TYPE OF COVER"
                            name="coverType"
                            readOnly
                            disabled
                            value={getInfo(CC, "VEH_CC")}
                            onChange={(e) => e.target.value}
                          />
                        </div>
                      </div>
                      <div className="triple-input">
                        <div className="reg">
                          <label htmlFor="sumInsured">SUM INSURED</label>
                          <input
                            name="sumInsured"
                            readOnly
                            disabled
                            value={
                              sumInsured === "" ? "" : numFormat(sumInsured)
                            }
                            onChange={(e) => e.target.value}
                          />
                        </div>
                        <div className="reg">
                          <label htmlFor="sumInsured">VEHICLE VALUE</label>
                          <input
                            name="sumInsured"
                            readOnly
                            disabled
                            value={
                              VehicleValue === "" ? "" : numFormat(VehicleValue)
                            }
                            onChange={(e) => e.target.value}
                          />
                        </div>
                        {/* <div className="reg">
                          <label htmlFor="sumInsured">Rate</label>
                          <input
                            placeholder="SUM INSURED"
                            name="sumInsured"
                            readOnly
                            disabled
                            value={Rate}
                            onChange={(e) => e.target.value}
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    position: "relative",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
    color: "#000",
    innerWidth: "100%",
    outerWidth: "100%",
    height: "100%",
  },
  text: {
    marginTop: 230,
    marginLeft: 280,
    fontSize: "10px",
  },
  make: {
    position: "absolute",
    right: "70",
    top: "205",
    fontSize: "10px",
  },
  coverType: {
    position: "absolute",
    right: "46%",
    bottom: "30%",
    fontSize: "13px",
    fontWeight: "500",
  },
  regNo: {
    position: "absolute",
    left: "47%",
    top: "24.4%",
    fontSize: "10px",
  },
  entryDate: {
    position: "absolute",
    left: "47%",
    top: "33.4%",
    fontSize: "10px",
  },
  exitDate: {
    position: "absolute",
    left: "47%",
    top: "37.6%",
    fontSize: "10px",
  },
});
