import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { numFormat, paginate } from "../Components/CommonActions";
import { getFleetVehicles, getLovDetails } from "../services";
import { FileUpload } from "./FileUpload";
import FleetUpload from "./FleetUpload";
import { Vehicle } from "./Vehicle";

export const Corporates = () => {
  // const [corporate, setCorporate] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState(false);

  let { id, fleetCode, corporates } = useParams();

  const { data, status, refetch, isSuccess } = useQuery(
    "fleetvehicles",
    () => getFleetVehicles(fleetCode),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { data: myDetail } = useQuery("cor-det", getLovDetails);

  let navigate = useNavigate();

  const getInfo = (Item, type) => {
    let make;
    let makeCode;
    if (myDetail) {
      make = myDetail.find(
        (code) => code.pc_code === Item && code.pc_type === type
      );
      makeCode = make && make.pc_desc;
    }
    return makeCode;
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;

  const displayFleetVehicles =
    data &&
    data.slice(pagesVisited, pagesVisited + usersPerPage).map((vehicle) => {
      return (
        <tr
          key={vehicle.id}
          onClick={() => {
            // navigate(`/${corporates}/vehicle/${vehicle.id}`);
            sessionStorage.setItem(
              process.env.REACT_APP_VEHICLE_ID,
              vehicle.id
            );
            sessionStorage.setItem(
              process.env.REACT_APP_CORPORATE_PAYMENT,
              true
            );
          }}
        >
          <td>{getInfo(vehicle.Model, "VEHI_MOD")}</td>
          <td>{getInfo(vehicle.Make, "MOT_VEH_MAKE")}</td>
          <td>{vehicle.RegNo}</td>
          <td>{vehicle.chasisNumber}</td>
          <td>{numFormat(vehicle.sumInsured)}</td>
          <td
            className="dots"
            onClick={() => {
              setVehicleDetails(true);
            }}
          >
            ...
          </td>
        </tr>
      );
    });

  const pageCount = Math.ceil(data && data.length / usersPerPage);
  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard">
        <div className="dashboard-header">
          <div>
            <h1>{fleetCode}</h1>
            {data && <p>{`${isSuccess && data.length} vehicles registered`}</p>}
          </div>
          <p></p>
        </div>
        <table>
          <thead>
            <tr>
              <th>Model</th>
              <th>Make</th>
              <th>Registration No</th>
              <th>Chasis Number</th>
              <th>Sum Insured</th>
              <th className="dots">...</th>
            </tr>
          </thead>
          <tbody>
            {status === "loading" ? (
              <div>loading...</div>
            ) : status === "error" ? (
              <div>Error</div>
            ) : (
              <>{displayFleetVehicles}</>
            )}
          </tbody>
        </table>
        {paginate(pageCount, handlePageClick)}
      </div>
      {vehicleDetails && (
        <Vehicle
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
        />
      )}
      <FleetUpload
        id={id}
        isActive={isActive}
        setIsActive={setIsActive}
        refetch={refetch}
      />
      <FileUpload />
    </div>
  );
};
export default Corporates;
