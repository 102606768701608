import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { useAlert } from "react-alert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { useState } from "react";
import { useEffect } from "react";
import VehicleInformation from "./VehicleInformation";
import { createIndividual, getIndividuals } from "../services";
// import FullProcessVehicle from "../Components/FullProcessVehicle";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css";
import { notify } from "../Components/CommonActions";
import FullProcessVehicle from "../Components/FullProcessVehicle";
import { format } from "date-fns";

const CustomerInformation = ({
  isCustomer,
  setIsCustomer,
  isNavUpload,
  queryClient,
  editId,
  setEditId,
  // setIsFullProcess,
}) => {
  const alert = useAlert();
  const [select, setSelect] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      gender: "",
      dateOfBirth: "",
      email: "",
      occupation: "",
      nationality: "",
      address: "",
      brokerCode: "",
      rate: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("required"),
      middleName: Yup.string(),
      lastName: Yup.string().required("required"),
      phone: Yup.number(),
      gender: Yup.string(),
      dateOfBirth: Yup.date()
        .max(new Date(Date.now() - 567648000000))
        .required("required"),
      email: Yup.string().email("Invalid email address").required("required"),
      occupation: Yup.string(),
      nationality: Yup.string(),
      address: Yup.string().required("required"),
      brokerCode: Yup.string(),
      // rate: Yup.number(),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsAdding(true);
      createIndividual(values)
        .then((res) => {
          notify("success", "Success", res);
          queryClient.refetchQueries();
          setIsCustomer(false);
          resetForm({ values: "" });
          setIsAdding(false);
        })
        .catch((error) => {
          setIsAdding(false);
          notify("danger", "Error", "Something went wrong");
        });

      // window.location.reload(false)

      // setIsFullProcess(false)
      // window.location.reload(false)
    },
  });

  const { data, status, isSuccess } = useQuery(
    "individualspage",
    getIndividuals,
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  let navigate = useNavigate();

  const customer = (array) => {
    let lists;
    lists =
      array &&
      array.map((datas) => {
        return (
          <option
            key={datas.id}
          >{`${datas.firstName} ${datas.lastName}`}</option>
        );
      });
    return lists;
  };

  let list;
  let firstName;
  let lastName;
  let middleName;
  let phone;
  let gender;
  let dateOfBirth;
  let email;
  let occupation;
  let nationality;
  let address;
  let rate;
  let individualId;
  list =
    data &&
    data.find((info) => `${info.firstName} ${info.lastName}` === select);
  firstName = list && list.firstName;
  lastName = list && list.lastName;
  middleName = list && list.middleName;
  phone = list && list.phone;
  gender = list && list.gender;
  dateOfBirth = list && list.dateOfBirth;
  email = list && list.email;
  occupation = list && list.occupation;
  nationality = list && list.nationality;
  address = list && list.address;
  rate = list && list.rate;
  individualId = list && list.id;

  return (
    <div
      className={
        isCustomer
          ? "customer-container show-customer-container"
          : "customer-container"
      }
    >
      <div className="customer-information">
        <div className="customer-information-header">
          <h1 className="header">Customer Information</h1>
          <IoCloseOutline
            className="btn-close"
            onClick={() => {
              isNavUpload && window.location.reload(false);
              setIsCustomer(false);
              formik.resetForm({ values: "" });
            }}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          {isNavUpload && (
            <select
              name="select"
              value={select}
              onChange={(e) => setSelect(e.target.value)}
              onClick={() => {
                getIndividuals();
              }}
            >
              <option>select customer</option>
              {customer(data)}
            </select>
          )}
          <div className="triple-input">
            <div>
              <label htmlFor="firstName">First Name</label>
              <input
                className={
                  formik.touched.firstName && formik.errors.firstName
                    ? "error"
                    : ""
                }
                name="firstName"
                value={isNavUpload ? firstName : formik.values.firstName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </div>
            {/* {formik.errors.firstName ? <p>{formik.errors.firstName}</p> : null} */}
            <div>
              <label htmlFor="middleName">Middle Name</label>
              <input
                name="middleName"
                onChange={formik.handleChange}
                value={isNavUpload ? middleName : formik.values.middleName}
              />
            </div>
            <div>
              <label htmlFor="lastName">Last Name</label>
              <input
                className={
                  formik.touched.lastName && formik.errors.lastName
                    ? "error"
                    : ""
                }
                name="lastName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={isNavUpload ? lastName : formik.values.lastName}
              />
            </div>
          </div>
          <div className="triple-input">
            <div>
              <label htmlFor="phone">Phone</label>
              <input
                name="phone"
                onChange={formik.handleChange}
                value={isNavUpload ? phone : formik.values.phone}
                type="number"
              />
            </div>
            <div>
              <label htmlFor="gender">Gender</label>
              <select
                name="gender"
                onChange={formik.handleChange}
                value={isNavUpload ? gender : formik.values.gender}
              >
                <option value=""></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div>
              <label htmlFor="dateOfBirth">Date of birth</label>
              <input
                name="dateOfBirth"
                type={isNavUpload ? "text" : "date"}
                onChange={formik.handleChange}
                value={
                  isNavUpload
                    ? dateOfBirth && format(new Date(dateOfBirth), "yyyy/MM/dd")
                    : formik.values.dateOfBirth
                }
                className={
                  formik.touched.dateOfBirth && formik.errors.dateOfBirth
                    ? "error"
                    : ""
                }
              />
            </div>
          </div>
          <div>
            <label htmlFor="email">Email Address</label>
            <input
              name="email"
              className={
                formik.touched.email && formik.errors.email ? "error" : ""
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={isNavUpload ? email : formik.values.email}
            />
          </div>
          <div className="double-input">
            <div>
              <label htmlFor="occupation">Occupation</label>
              <input
                name="occupation"
                onChange={formik.handleChange}
                value={isNavUpload ? occupation : formik.values.occupation}
              />
            </div>
            <div>
              <label htmlFor="nationality">Nationality</label>
              <input
                name="nationality"
                onChange={formik.handleChange}
                value={isNavUpload ? nationality : formik.values.nationality}
              />
            </div>
          </div>
          <div>
            <label htmlFor="address">Address</label>
            <input
              name="address"
              onChange={formik.handleChange}
              value={isNavUpload ? address : formik.values.address}
              className={
                formik.touched.address && formik.errors.address ? "error" : ""
              }
              onBlur={formik.handleBlur}
            />
          </div>
          {/* <div>
            <label htmlFor="rate">Rate</label>
            <input
              name="rate"
              value={isNavUpload ? rate : formik.values.rate}
              onChange={formik.handleChange}
            />
          </div> */}

          {!isNavUpload && (
            <button type="submit" className="btn-next">
              {isAdding ? "Adding..." : "Add"}
            </button>
          )}
        </form>
        {isNavUpload && (
          <button
            onClick={() => {
              setIsActive(true);
              sessionStorage.setItem(process.env.REACT_APP_ID, individualId);
              sessionStorage.setItem(
                process.env.REACT_APP_CUSTOMER_NAME,
                select
              );
            }}
            className="btn-next"
          >
            NEXT
          </button>
        )}
      </div>
      <FullProcessVehicle
        isActive={isActive}
        setIsActive={setIsActive}
        individualId={individualId}
        isNavUpload={isNavUpload}
        isCustomer={isCustomer}
        setIsCustomer={setIsCustomer}
      />
    </div>
  );
};

export default CustomerInformation;
