import React from "react";
import { useQuery } from "react-query";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
} from "recharts";
import { getIndividuals } from "../services";

export const Dashboard = () => {
  const datas = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];

  const { data, status, refetch } = useQuery(
    "individualspage",
    getIndividuals,
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return (
    <div className="dashboard-container">
      <div className="dashboard">
        {/* <div className="dashboard-header">
          <h1>Dashboard</h1>
          <button>+ Dash button</button>
        </div> */}
        <div className="dashboard-wrapper">
          <div className="dash-left">
            <div className="dash-left-top">
              <div className="dash-left-top-top">
                <div className="dash-right-bottom-right">
                  <p>Corporates</p>
                  <h1>10</h1>
                </div>
                <div className="dash-right-bottom-left">
                  <p>Individuals</p>
                  <h1>15</h1>
                </div>
              </div>
              <div className="dash-left-top-bottom">Left top bottom</div>
            </div>
            <div className="dash-left-bottom">
              <div className="search-wrapper">
                <input placeholder="Search" />
                <div className="search-options">
                  <p>CORP</p>
                  <p>IND</p>
                </div>
              </div>

              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                {data &&
                  data.map((individual) => {
                    console.log(individual);
                    return (
                      <tbody>
                        <tr>
                          <td>{individual.firstName}</td>
                          <td>{individual.firstName}</td>
                          <td>{individual.firstName}</td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>

              {/* {data &&
                data.map((individual) => {
                  return (
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{individual.firstName}</td>
                          <td>{individual.firstName}</td>
                          <td>{individual.firstName}</td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })} */}
            </div>
          </div>
          <div className="dash-right">
            <div className="dash-right-top">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={datas}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" fontSize={10} />
                  <YAxis fontSize={10} />
                  <Tooltip />
                  {/* <Legend /> */}
                  <Bar dataKey="pv" fill="#8884d8" />
                  <Bar dataKey="uv" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="dash-right-bottom">
              <div className="dash-right-bottom-left">Right bottom left</div>
              <h1 className="dash-right-bottom-right">Right bottom right</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
