import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./Styles/App.css";
import "./Styles/Style.css";
import "./Styles/Table.css";
import "./Styles/Dashboard.css";
import "react-animated-router/animate.css";
import { AuthContext } from "./helpers/AuthContext";
import IndividualsPage from "./Pages/IndividualsPage";
import { QueryClient, QueryClientProvider } from "react-query";
import { Individuals } from "./Pages/Individuals";
import CorporatesPage from "./Pages/CorporatesPage";
import Corporates from "./Pages/Corporates";
import { Vehicle } from "./Pages/Vehicle";
import { Dashboard } from "./Pages/Dashboard";
import { Navbar } from "./Components/Navbar";
import { PaystackIntegration } from "./Pages/PaystackIntegration";
import { positions, Provider, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { FileUpload } from "./Pages/FileUpload";
import CorporatesFleets from "./Pages/CorporateFleets";
import { Search } from "./Components/Search";
// import FullProcess from "./Components/FullProcess";
import { NavFileUpload } from "./Pages/NavFileUpload";
import { ReactNotifications } from "react-notifications-component";
import { TestFile } from "./Pages/TestFile";

const queryCLient = new QueryClient();

const LoginPage = lazy(() => import("./Pages/LoginPage"));
const LoadingMessage = () => "loading...";

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
  transition: transitions.FADE,
};

function App() {
  const [authState, setAuthState] = useState(false);
  const [authAdminState, setAuthAdminState] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isIndividualCreditNotePay, setIsIndividualCreditNotePay] =
    useState(false);
  const [navFileUpload, setNavFileUpload] = useState(false);
  // const [isFullProcess, setIsFullProcess] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(process.env.REACT_APP_USER_TOKEN)) {
      setAuthState(true);
    }
    if (localStorage.getItem(process.env.REACT_APP_ADMIN_TOKEN)) {
      setAuthAdminState(true);
    }
  }, []);

  return (
    // <Suspense fallback={<LoadingMessage />}></Suspense> ---- for a fallback when the component is not ready yet
    <Provider template={AlertTemplate} {...options}>
      <QueryClientProvider client={queryCLient}>
        <AuthContext.Provider
          value={{
            authState,
            setAuthState,
            authAdminState,
            setAuthAdminState,
            isPayment,
            setIsPayment,
            // isFullProcess,
            // setIsFullProcess,
            navFileUpload,
            setNavFileUpload,
            userData: JSON.parse(
              localStorage.getItem(process.env.REACT_APP_USER_DATA)
            ),
            adminData: JSON.parse(
              localStorage.getItem(process.env.REACT_APP_ADMIN_DATA)
            ),
            isUpload,
            setIsUpload,
            queryCLient,
            isIndividualCreditNotePay,
            setIsIndividualCreditNotePay,
          }}
        >
          <>
            <ReactNotifications />
            <div className="app">
              <Suspense>
                <Router>
                  {authState && (
                    <>
                      <FileUpload />
                      <NavFileUpload />
                      {isPayment && <PaystackIntegration />}
                      <Navbar
                        setAuthState={setAuthState}
                        setIsLogout={setIsLogout}
                      />
                      {/* <FullProcess /> */}
                      <div
                        className={
                          isLogout
                            ? "customer-container log-out-container show-customer-container"
                            : "customer-container"
                        }
                      >
                        <div className="customer-information log-out">
                          <h1>CONFIRM LOGOUT</h1>
                          <p>Are you sure you want to Log out?</p>
                          <div>
                            <Link
                              onClick={() => {
                                setAuthState(false);
                                localStorage.clear("accessToken");
                                setIsLogout(false);
                              }}
                              to={"/"}
                            >
                              Confirm
                            </Link>
                            <button onClick={() => setIsLogout(false)}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <Routes>
                    <Route
                      path="/"
                      element={authState ? <IndividualsPage /> : <LoginPage />}
                    />

                    {authState && (
                      <>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route
                          path="/individuals"
                          element={<IndividualsPage />}
                        />
                        <Route
                          path="/:individual/:id"
                          element={<Individuals />}
                        />
                        <Route
                          path="/:corporates/vehicle/:id"
                          element={<Vehicle />}
                        />
                        <Route
                          path="/corporates"
                          element={<CorporatesPage />}
                        />
                        <Route
                          path="/corporates/:id"
                          element={<CorporatesFleets />}
                        />
                        <Route
                          path="/corporates/:id/fleet/:fleetCode"
                          element={<Corporates />}
                        />
                        <Route path="/file" element={<TestFile />} />
                        <Route path="/file" element={<FileUpload />} />
                        <Route path="/search" element={<Search />} />
                      </>
                    )}
                  </Routes>
                </Router>
              </Suspense>
            </div>
          </>
        </AuthContext.Provider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
