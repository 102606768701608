import { useAlert } from "react-alert";
import { IoCloseOutline } from "react-icons/io5";
import React, { useState, useEffect } from "react";
// import { getAllBrokers, newCustomer } from "../services";
import { QueryClient, useQuery } from "react-query";
import { newCustomer } from "../services";
import { notify } from "../Components/CommonActions";
// import { notify } from "./CommonActions";

const CorporateInformation = ({
  isClient,
  setIsClient,
  queryCLient,
  refetch,
}) => {
  const userData = localStorage.getItem(process.env.REACT_APP_USER_DATA);
  const parsedUserData = JSON.parse(userData);

  console.log("userrrr", parsedUserData);

  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    phone: "",
    natureOfBusiness: "",
    address: "",
    brokerId: parsedUserData.brokerId,
    agreedRate: "",
    thirdPartyPremium: "",
    commercialPremium: "",
    privatePremium: "",
    motorcyclePremium: "",
  });
  const [selectBroker, setSelectBroker] = useState("");

  //   const { data, status, isSuccess, refetch } = useQuery(
  //     "all-brokers",
  //     getAllBrokers
  //   );
  const addCustomer = async () => {
    if (
      isEmailError
      // !customer.name ||
      // !customer.email ||
      // !customer.brokerId
    ) {
      notify("danger", "Error", "Please make sure all inputs are valid");
    } else {
      setIsLoading(true);
      await newCustomer(customer)
        .then((res) => {
          notify("success", "Success", res);
          setIsClient(false);
          refetch();
          handleReset();
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          notify(
            "danger",
            "Error",
            "Something went wrong, contact administrator"
          );
        });
    }
  };

  //   let brokerList;
  //   if (data) {
  //     brokerList = data.map((datas) => {
  //       return <option key={datas.id}>{datas.brokerName}</option>;
  //     });
  //   }

  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    // if (name === "brokerId") {
    //   let filteredBroker;
    //   let filteredBrokerId;
    //   //   if (data) {
    //   //     filteredBroker = data && data.find((item) => item.brokerName === value);
    //   //     filteredBrokerId = filteredBroker && filteredBroker.id;
    //   //   }
    //   setCustomer((prevState) => ({
    //     ...prevState,
    //     [name]: filteredBrokerId,
    //   }));
    //   return;
    // }
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setCustomer({
      name: "",
      email: "",
      phone: "",
      natureOfBusiness: "",
      address: "",
      brokerId: parsedUserData.brokerId,
      agreedRate: "",
      thirdPartyPremium: "",
      commercialPremium: "",
      privatePremium: "",
      motorcyclePremium: "",
    });
  };
  return (
    <div
      className={
        isClient
          ? "customer-container show-customer-container"
          : "customer-container"
      }
    >
      <div className="customer-information admin-form">
        <div className="customer-information-header">
          <h1 className="header">Add New Client To Broker</h1>
          <IoCloseOutline
            className="btn-close"
            onClick={() => {
              setIsClient(!isClient);
              handleReset();
            }}
          />
        </div>
        {/* <div className="input-container">
          <label>Select Broker</label>
          <select name="brokerId" onChange={handleCustomerChange}>
            <option value=""></option>
            {brokerList}
          </select>
        </div> */}
        <div className="input-container">
          <label>Name</label>
          <input
            name="name"
            onChange={handleCustomerChange}
            value={customer.name}
          />
        </div>
        <div className="double-input">
          <div className="input-container">
            <label style={{ color: isEmailError && "red" }}>
              {isEmailError ? "Email address is invalid" : "Email"}
            </label>
            <input
              name="email"
              onChange={handleCustomerChange}
              value={customer.email}
              onBlur={() => {
                if (customer.email.search(/@/) === -1) {
                  setIsEmailError(true);
                } else {
                  setIsEmailError(false);
                }
              }}
            />
          </div>
          <div className="input-container">
            <label>Phone</label>
            <input
              name="phone"
              type="number"
              onChange={handleCustomerChange}
              style={{
                "-moz-appearance": "textfield",
                "-webkit-appearance": "textfield",
              }}
              value={customer.phone}
            />
          </div>
        </div>
        <div className="input-container">
          <label>Nature of Business</label>
          <input
            name="natureOfBusiness"
            onChange={handleCustomerChange}
            value={customer.natureOfBusiness}
          />
        </div>
        <div className="input-container">
          <label>Address</label>
          <input
            name="address"
            onChange={handleCustomerChange}
            value={customer.address}
          />
        </div>
        <div className="input-container">
          <label>Motor Comprehensive Rate %</label>
          <input
            name="agreedRate"
            type="number"
            onChange={handleCustomerChange}
            value={customer.agreedRate}
          />
        </div>
        <p className="third-party-head">Third Party Details</p>
        {/* <div className="input-container">
          <label>Third Party Premium</label>
          <input name="thirdPartyPremium" onChange={handleCustomerChange} />
        </div> */}
        <div className="triple-input">
          <div className="input-container">
            <label>Commercial</label>
            <input
              name="commercialPremium"
              type="number"
              onChange={handleCustomerChange}
              value={customer.commercialPremium}
            />
          </div>
          <div className="input-container">
            <label>Private</label>
            <input
              name="privatePremium"
              type="number"
              onChange={handleCustomerChange}
              value={customer.privatePremium}
            />
          </div>
          <div className="input-container">
            <label>Motorcycle</label>
            <input
              name="motorcyclePremium"
              type="number"
              onChange={handleCustomerChange}
              value={customer.motorcyclePremium}
            />
          </div>
        </div>
        <button
          onClick={() => {
            addCustomer();
          }}
          className="btn-login"
        >
          {isLoading ? "ADDING CUSTOMER..." : "ADD"}
        </button>
      </div>
    </div>
  );
};

export default CorporateInformation;
