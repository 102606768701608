import React, { useState, useEffect, useContext } from "react";
import { IoIosPerson } from "react-icons/io";
import { FaIndustry, FaMoneyBill } from "react-icons/fa";
import { BiLogOut, BiPlusCircle } from "react-icons/bi";
import darkLogo from "../../src/Photos/dark-logo.svg";
import { Link, NavLink } from "react-router-dom";
import CustomerInformation from "../Pages/CustomerInformation";
import FleetUpload from "../Pages/FleetUpload";

import { AuthContext } from "../helpers/AuthContext";
import AddFleet from "./AddFleet";
import { useQueryClient } from "react-query";

export const Navbar = ({ setAuthState, setIsLogout }) => {
  const [broker, setBroker] = useState([]);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isNavUpload, setIsNavUpload] = useState(false);
  const { userData, isFullProcess, setIsFullProcess } = useContext(AuthContext);
  const [addVehicles, setAddVehicles] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const queryClient = useQueryClient();
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setAuthState(true);
    }
  }, []);

  return (
    <div className="navigation">
      <div className="nav-container">
        <div className="logo-container">
          <img src={darkLogo} alt="logo" />
        </div>
        <ul>
          <div className="nav-upload-container">
            <Link
              className="link"
              activeclassname="active"
              onClick={() => {
                // setIsFullProcess(true);
                setAddVehicles(!addVehicles);
              }}
            >
              <li className="nav-link">
                <BiPlusCircle className="icon" /> Add Vehicles
              </li>
            </Link>
            {addVehicles && (
              <>
                <Link
                  className="link"
                  onClick={() => {
                    setIsIndividual(!isIndividual);
                  }}
                >
                  <li className="nav-link">
                    <BiPlusCircle className="icon" /> Individual Clients
                  </li>
                </Link>
                {isIndividual && (
                  <>
                    <Link
                      className="link"
                      onClick={() => {
                        setIsCustomer(true);
                        setIsNavUpload(false);
                      }}
                    >
                      <li className="nav-link">
                        <BiPlusCircle className="icon" /> New
                      </li>
                    </Link>
                    <Link
                      className="link"
                      onClick={() => {
                        setIsCustomer(true);
                        setIsNavUpload(true);
                      }}
                    >
                      <li className="nav-link">
                        <BiPlusCircle className="icon" /> Existing
                      </li>
                    </Link>
                  </>
                )}
                <Link
                  className="link"
                  onClick={() => {
                    setIsActive(true);
                    setIsNavUpload(true);
                  }}
                >
                  <li className="nav-link">
                    <BiPlusCircle className="icon" /> Corporate Client
                  </li>
                </Link>
              </>
            )}
          </div>

          {/* <NavLink
            className="link"
            to="/dashboard"
            onClick={() => {
              setIsIndividual(false);
              setAddVehicles(false);
            }}
          >
            <li className="nav-link">
              <IoIosPerson className="icon" /> Dashboard
            </li>
          </NavLink> */}
          <NavLink
            className="link"
            to="/individuals"
            onClick={() => {
              setIsIndividual(false);
              setAddVehicles(false);
            }}
          >
            <li className="nav-link">
              <IoIosPerson className="icon" /> Individuals
            </li>
          </NavLink>
          <NavLink
            className="link"
            activeclassname="active"
            to="/corporates"
            onClick={() => {
              setIsIndividual(false);
              setAddVehicles(false);
            }}
          >
            <li className="nav-link">
              <FaIndustry className="icon" />
              Corporate
            </li>
          </NavLink>
        </ul>
      </div>
      <div className="navigation-footer">
        <div className="broker-details">
          <h5>{userData && userData.brokerName}</h5>
          <p>{userData && userData.brokerEmail}</p>
          <p>{userData && userData.brokerCode.toUpperCase()}</p>
          <Link onClick={() => setIsLogout(true)} className="logout">
            Logout
            <BiLogOut />
          </Link>
        </div>
      </div>
      <CustomerInformation
        isCustomer={isCustomer}
        setIsCustomer={setIsCustomer}
        isNavUpload={isNavUpload}
        queryClient={queryClient}
      />
      <AddFleet isActive={isActive} setIsActive={setIsActive} />
    </div>
  );
};
