import React from "react";
import axios from "axios";

export const TestFile = () => {
  const uploadFile = async (e) => {
    const formData = new FormData();
    const file = e.target.files || e.dataTransfer.files;

    if (file && file.length < 1) {
      alert("Please upload a file");
      return;
    }

    formData.append("file", file[0]);

    try {
      const res = await axios.post(
        "http://staging-upgrade.fbninsurance.co/api/file-upload",
        formData
      );
    } catch (error) {
      alert("Something went wrong.");
    }
  };
  return (
    <div>
      <input type="file" onChange={uploadFile} className="upload-files" />
    </div>
  );
};
