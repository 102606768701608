import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { useAlert } from "react-alert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { useState } from "react";
import { useEffect } from "react";
import VehicleInformation from "../Pages/VehicleInformation";
import { getIndividuals, updateIndividual } from "../services";
import { notify } from "./CommonActions";
import { format } from "date-fns";

const EditCustomer = ({
  isCustomer,
  setIsCustomer,
  refetch,
  editId,
  isEdit,
  setIsEdit,
  queryClient,
  currentCustomer,
}) => {
  const alert = useAlert();
  const [message, setMessage] = useState("");
  const formik = useFormik({
    initialValues: {
      firstName: currentCustomer.firstName,
      middleName: currentCustomer.middleName,
      lastName: currentCustomer.lastName,
      phone: currentCustomer.phone,
      gender: currentCustomer.gender,
      dateOfBirth: format(new Date(currentCustomer.dateOfBirth), "yyyy-MM-dd"),
      email: currentCustomer.email,
      occupation: currentCustomer.occupation,
      nationality: currentCustomer.nationality,
      address: currentCustomer.address,
      brokerCode: currentCustomer.brokerCode,
      rate: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("required"),
      middleName: Yup.string(),
      lastName: Yup.string().required("required"),
      phone: Yup.number(),
      gender: Yup.string(),
      dateOfBirth: Yup.date().required(),
      email: Yup.string().email("Invalid email address").required("required"),
      occupation: Yup.string(),
      nationality: Yup.string(),
      address: Yup.string().required("required"),
      brokerCode: Yup.string(),
      rate: Yup.number(),
    }),
    onSubmit: async (values, { resetForm }) => {
      await updateIndividual(values, editUser.id)
        .then((res) => {
          notify("success", "Success", "Customer edited successfully");
          setIsEdit(false);
          queryClient.refetchQueries();
          resetForm({ values: "" });
        })
        .catch((error) => {
          notify("danger", "Success", "Something went wrong");
          console.log(error);
        });
      setIsCustomer(false);
      resetForm({ values: "" });
    },
  });

  const { data, status, isSuccess } = useQuery(
    "individualspage",
    getIndividuals,
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  
  let editUser = data && data.find((myUser) => myUser.id === editId);
  console.log(editUser.id);

  return (
    <div
      className={
        isEdit
          ? "customer-container show-customer-container"
          : "customer-container"
      }
    >
      <div className="customer-information">
        <div className="customer-information-header">
          <h1 className="header">Edit Customer Information</h1>
          <IoCloseOutline
            className="btn-close"
            onClick={() => setIsEdit(!isEdit)}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="triple-input">
            <div>
              <label htmlFor="firstName">First Name</label>
              <input
                className={
                  formik.touched.firstName && formik.errors.firstName
                    ? "error"
                    : ""
                }
                name="firstName"
                value={formik.values.firstName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </div>
            {/* {formik.errors.firstName ? <p>{formik.errors.firstName}</p> : null} */}
            <div>
              <label htmlFor="middleName">Middle Name</label>
              <input
                name="middleName"
                onChange={formik.handleChange}
                value={formik.values.middleName}
              />
            </div>
            <div>
              <label htmlFor="lastName">Last Name</label>
              <input
                className={
                  formik.touched.lastName && formik.errors.lastName
                    ? "error"
                    : ""
                }
                name="lastName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
            </div>
          </div>
          <div className="triple-input">
            <div>
              <label htmlFor="phone">Phone</label>
              <input
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
            </div>
            <div>
              <label htmlFor="gender">Gender</label>
              <input
                name="gender"
                onChange={formik.handleChange}
                value={formik.values.gender}
              />
            </div>
            <div>
              <label htmlFor="dateOfBirth">Date of birth</label>
              <input
                name="dateOfBirth"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.dateOfBirth}
                className={
                  formik.touched.dateOfBirth && formik.errors.dateOfBirth
                    ? "error"
                    : ""
                }
              />
            </div>
          </div>
          <div>
            <label htmlFor="email">Email Address</label>
            <input
              name="email"
              className={
                formik.touched.email && formik.errors.email ? "error" : ""
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </div>
          <div className="double-input">
            <div>
              <label htmlFor="occupation">Occupation</label>
              <input
                name="occupation"
                onChange={formik.handleChange}
                value={formik.values.occupation}
              />
            </div>
            <div>
              <label htmlFor="nationality">Nationality</label>
              <input
                name="nationality"
                onChange={formik.handleChange}
                value={formik.values.nationality}
              />
            </div>
          </div>
          <div>
            <label htmlFor="address">Address</label>
            <input
              name="address"
              onChange={formik.handleChange}
              value={formik.values.address}
              className={
                formik.touched.address && formik.errors.address ? "error" : ""
              }
              onBlur={formik.handleBlur}
            />
          </div>
          {/* <div>
            <label htmlFor="rate">Rate</label>
            <input
              name="rate"
              value={formik.values.rate}
              onChange={formik.handleChange}
            />
          </div> */}
          <button type="submit" className="btn-next">
            UPDATE
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditCustomer;
