import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import CertificateImage from "../Photos/CertificateImg.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    position: 'relative'
  },
  // section: {
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1
  // }
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
    color: '#000',
    innerWidth: '100%',
    outerWidth: '100%',
    height: '100%'
  },
  text: {
    marginTop: 290,
    marginLeft: 280,
    fontSize: '11px',
  },
  make: {
    position: 'absolute',
    right: '23%',
    top: '32%',
    fontSize: '11px'
  }
});


// Create Document Component
export const Certificate = ({Make}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.text}>Customer Name</Text>
      <Text style={styles.make}>dfs{Make}</Text>
      <Image src={CertificateImage} style={styles.image} />
    </Page>
  </Document>
);
