import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import check from "../Photos/check-mark.png";
import { IoCloseOutline } from "react-icons/io5";
import "animate.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAlert } from "react-alert";
import { AuthContext } from "../helpers/AuthContext";
import { createVehicle } from "../services";
import { notify, numFormat } from "../Components/CommonActions";
import { useEffect } from "react";

const VehicleInformation = ({
  id,
  isActive,
  setIsActive,
  refetch,
  individualId,
  isNavUpload,
  queryClient,
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [color, setColor] = useState("");
  const [model, setModel] = useState("");
  const [make, setMake] = useState("");
  const [bodyType, setBodyType] = useState("");
  const [usageType, setUsageType] = useState("");
  const [coverCode, setCoverCode] = useState("");
  const [cC, setCC] = useState("");
  const [location, setLocation] = useState("");
  const [coverType, setCoverType] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [policyNo, setPolicyNo] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [duration, setDuration] = useState("");
  const alert = useAlert();
  const { isPayment, setIsPayment } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      VehEntryDt: null,
      // VehExitDt: null,
      CertReqdYN: "",
      CertMode: "",
      CertType: "",
      CertFmDt: null,
      CertToDt: null,
      UsageType: "",
      TypeOfcover: "",
      Make: "",
      Model: "",
      BodyType: "",
      RegNo: "",
      chasisNumber: "",
      engineNumber: "",
      MfgYear: "",
      Colour: "",
      PlaceOfRegn: "",
      VehicleValue: "",
      Premium: "",
      CC: "",
      CoverCode: "",
      Rate: "",
      brokerId: "",
      clientId: "",
      corporateId: null,
    },
    validationSchema: Yup.object({
      VehEntryDt: Yup.date().default(() => new Date()),
      UsageType: Yup.string().required(),
      TypeOfcover: Yup.string().required(),
      Make: Yup.string().required(),
      Model: Yup.string().required(),
      BodyType: Yup.string().required(),
      RegNo: Yup.string().required(),
      chasisNumber: Yup.string().required(),
      engineNumber: Yup.string().required(),
      MfgYear: Yup.string().required(),
      Colour: Yup.string().required(),
      PlaceOfRegn: Yup.string().required(),
      VehicleValue: Yup.string().when("TypeOfcover", {
        is: (val) => val !== "2" && val !== "3",
        then: Yup.string().required("Vehicle Value is required"),
        otherwise: Yup.string(),
      }),
      CC: Yup.string().required(),
      Premium: Yup.string().when("TypeOfCover", {
        is: (val) => val === "2" || val === "3",
        then: Yup.string().required("Premium is required"),
        otherwise: Yup.string(),
      }),
      Rate: Yup.string().when("TypeOfcover", {
        is: (val) => val !== "2" && val !== "3",
        then: Yup.string().required("Rate is required"),
        otherwise: Yup.string(),
      }),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsAdding(true);
      createVehicle({
        ...values,
        clientId: isNavUpload ? individualId : id,
        status: "Not Paid",
        policyNo: `MF/AUTO/${currentYear}/${policyNo}`,
        duration:
          formik.values.TypeOfcover.includes(2) ||
          formik.values.TypeOfcover.includes(3)
            ? "1"
            : duration,
        totalPremium:
          formik.values.TypeOfcover.includes(2) ||
          formik.values.TypeOfcover.includes(3)
            ? formik.values.Premium
            : (formik.values.Rate / 100) * formik.values.VehicleValue,
        payablePremium:
          formik.values.TypeOfcover.includes(2) ||
          formik.values.TypeOfcover.includes(3)
            ? formik.values.Premium
            : ((formik.values.Rate / 100) * formik.values.VehicleValue) /
              duration,
        sumInsured: formik.values.VehicleValue,
        VehExitDt: addMonths(12, formik.values.VehEntryDt),
      }).then((res) => {
        sessionStorage.setItem(process.env.REACT_APP_PLAY, details);
        notify("success", "Success", res);
        setIsSuccess(true);
        resetForm({ values: "" });
        queryClient.refetchQueries();
        setIsAdding(false);
      });
    },
  });

  useEffect(() => {
    setPolicyNo(Math.random().toString(36).substr(2, 5));
    setCurrentYear(new Date().getFullYear());
  }, []);

  const transactionDetails = {
    VehicleValue: formik.values.VehicleValue,
    sumInsured: formik.values.VehicleValue,
    totalPremium:
      formik.values.TypeOfcover.includes(2) ||
      formik.values.TypeOfcover.includes(3)
        ? formik.values.Premium
        : (formik.values.Rate / 100) * formik.values.VehicleValue,
    payablePremium:
      formik.values.TypeOfcover.includes(2) ||
      formik.values.TypeOfcover.includes(3)
        ? formik.values.Premium
        : ((formik.values.Rate / 100) * formik.values.VehicleValue) / duration,
    duration:
      formik.values.TypeOfcover.includes(2) ||
      formik.values.TypeOfcover.includes(3)
        ? "1"
        : duration,
    Rate: formik.values.Rate,
    RegNo: formik.values.RegNo,
  };

  const details = JSON.stringify(transactionDetails);

  function addMonths(numOfMonths, date) {
    let myDate = new Date(date);
    myDate.setMonth(myDate.getMonth() + numOfMonths);
    return myDate;
  }

  // --------------------------------------------------------------------------------------------------
  const getColor = async () => {
    try {
      const type = await fetch(`${process.env.REACT_APP_API_URL}lov/color`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      }).then((res) => res.json());
      setColor(type);
    } catch (error) {
      console.log(error);
    }
  };

  const getModel = async () => {
    try {
      const type = await fetch(`${process.env.REACT_APP_API_URL}lov/VEHI_MOD`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      }).then((res) => res.json());
      setModel(type);
    } catch (error) {
      console.log(error);
    }
  };
  const getMake = async () => {
    try {
      const type = await fetch(
        `${process.env.REACT_APP_API_URL}lov/MOT_VEH_MAKE`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      ).then((res) => res.json());
      setMake(type);
    } catch (error) {
      console.log(error);
    }
  };
  const getBodyType = async () => {
    try {
      const type = await fetch(
        `${process.env.REACT_APP_API_URL}lov/MOT_BODY_TYP`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      ).then((res) => res.json());
      setBodyType(type);
    } catch (error) {
      console.log(error);
    }
  };
  const getUsageType = async () => {
    try {
      const type = await fetch(`${process.env.REACT_APP_API_URL}lov/VEH_TYPE`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      }).then((res) => res.json());
      setUsageType(type);
    } catch (error) {
      console.log(error);
    }
  };
  const getCoverCode = async () => {
    try {
      const type = await fetch(
        `${process.env.REACT_APP_API_URL}lov/COVER_CODE`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      ).then((res) => res.json());
      setCoverCode(type);
    } catch (error) {
      console.log(error);
    }
  };
  const getCC = async () => {
    try {
      const type = await fetch(`${process.env.REACT_APP_API_URL}lov/VEH_CC`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      }).then((res) => res.json());
      setCC(type);
    } catch (error) {
      console.log(error);
    }
  };
  const getLocation = async () => {
    try {
      const type = await fetch(`${process.env.REACT_APP_API_URL}lov/LOCATION`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
          accessToken: localStorage.getItem("accessToken"),
        },
      }).then((res) => res.json());
      setLocation(type);
    } catch (error) {
      console.log(error);
    }
  };
  const getCoverType = async () => {
    try {
      const type = await fetch(
        `${process.env.REACT_APP_API_URL}lov/COVER_TYPE`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      ).then((res) => res.json());
      setCoverType(type);
    } catch (error) {
      console.log(error);
    }
  };

  // --------------------------------------------------------------------------------------------------

  const lovOption = (array) => {
    let list;
    list =
      array &&
      array.map((data) => {
        return (
          <option key={data.id} value={data.pc_code}>
            {data.pc_desc}
          </option>
        );
      });
    return list;
  };

  const today = new Date();
  const todayDate = today.toISOString().substr(0, 10);

  return (
    <div
      className={
        isActive
          ? "customer-container show-customer-container"
          : "customer-container"
      }
    >
      <div
        className={
          isSuccess ? "success-modal show-success-modal" : "success-modal"
        }
      >
        <div className="modal">
          <img
            src={check}
            alt="checkmark"
            className="checkmark animate__animated animate__fadeInRight"
          />
          <p>
            Your information has been successfully submitted with proposal id:
          </p>
          <h4>{`MF/AUTO/${currentYear}/${policyNo.toUpperCase()}`}</h4>
          <button
            className="btn-next"
            onClick={() => {
              setIsPayment(true);
              setIsSuccess(false);
              setIsActive(false);
              sessionStorage.setItem(process.env.REACT_APP_IS_UPDATE, false);
            }}
          >
            PROCEED TO CHECKOUT
          </button>
        </div>
      </div>
      <div className="customer-information users-form">
        <div className="customer-information-header">
          <h1 className="header">Vehicle Information</h1>
          <IoCloseOutline
            className="btn-close"
            onClick={() => {
              setIsActive(!isActive);
              formik.resetForm({ values: "" });
            }}
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <label htmlFor="TypeOfcover">Type of cover</label>
            <select
              className={
                formik.touched.TypeOfcover && formik.errors.TypeOfcover
                  ? "error"
                  : ""
              }
              name="TypeOfcover"
              onChange={formik.handleChange}
              value={formik.values.TypeOfcover}
              onFocus={() => getCoverType()}
            >
              <option></option>
              {lovOption(coverType)}
            </select>
          </div>
          <div
            className={
              !formik.values.TypeOfcover.includes(2) &&
              !formik.values.TypeOfcover.includes(3) &&
              "double-input"
            }
          >
            {!formik.values.TypeOfcover.includes(2) &&
              !formik.values.TypeOfcover.includes(3) && (
                <div>
                  <label>Frequency of payment</label>

                  <select
                    placeholder="DURATION"
                    name="Duration"
                    onChange={(e) => setDuration(e.target.value)}
                    // value={formik.values.VehExitDt}
                  >
                    <option></option>
                    <option value="4">Quarterly</option>
                    <option value="2">Bi-annual</option>
                    <option value="1">One off(yearly)</option>
                  </select>
                </div>
              )}
            <div>
              <label htmlFor="VehEntryDt">Entry Date</label>
              <input
                className={
                  formik.touched.VehEntryDt && formik.errors.VehEntryDt
                    ? "error"
                    : ""
                }
                name="VehEntryDt"
                onChange={formik.handleChange}
                value={formik.values.VehEntryDt}
                type="date"
                min={todayDate}
              />
            </div>
          </div>

          {/* <p>{addMonths(3)}</p> */}

          <div>
            <label htmlFor="UsageType">Usage Type</label>
            <select
              name="UsageType"
              className={
                formik.touched.UsageType && formik.errors.UsageType
                  ? "error"
                  : ""
              }
              onChange={formik.handleChange}
              value={formik.values.UsageType}
              onFocus={() => getUsageType()}
            >
              <option></option>
              {lovOption(usageType)}
            </select>
          </div>

          <div className="triple-input">
            <div>
              <label htmlFor="Make">Make</label>
              <select
                className={
                  formik.touched.Make && formik.errors.Make ? "error" : ""
                }
                name="Make"
                onFocus={() => getMake()}
                onChange={formik.handleChange}
                value={formik.values.Make}
              >
                <option></option>
                {lovOption(make)}
              </select>
            </div>
            <div>
              <label htmlFor="Model">Model</label>
              <select
                name="Model"
                className={
                  formik.touched.Model && formik.errors.Model ? "error" : ""
                }
                onChange={formik.handleChange}
                value={formik.values.Model}
                onFocus={() => {
                  getModel();
                }}
              >
                <option></option>
                {lovOption(model)}
              </select>
            </div>
            <div>
              <label htmlFor="BodyType">Body type</label>
              <select
                name="BodyType"
                className={
                  formik.touched.BodyType && formik.errors.BodyType
                    ? "error"
                    : ""
                }
                onFocus={() => getBodyType()}
                onChange={formik.handleChange}
                value={formik.values.BodyType}
              >
                <option></option>
                {lovOption(bodyType)}
              </select>
            </div>
          </div>
          <div className="triple-input">
            <div>
              <label htmlFor="RegNo">Registration Number</label>
              <input
                name="RegNo"
                className={
                  formik.touched.RegNo && formik.errors.RegNo ? "error" : ""
                }
                onChange={formik.handleChange}
                value={formik.values.RegNo}
              />
            </div>
            <div>
              <label htmlFor="chasisNumber">Chasis number</label>
              <input
                name="chasisNumber"
                onChange={formik.handleChange}
                value={formik.values.chasisNumber}
                className={
                  formik.touched.chasisNumber && formik.errors.chasisNumber
                    ? "error"
                    : ""
                }
              />
            </div>
            <div>
              <label htmlFor="engineNumber">Engine number</label>
              <input
                name="engineNumber"
                onChange={formik.handleChange}
                value={formik.values.engineNumber}
                className={
                  formik.touched.engineNumber && formik.errors.engineNumber
                    ? "error"
                    : ""
                }
              />
            </div>
          </div>
          <div className="double-input">
            <div>
              <label htmlFor="MfgYear">Manufactured Year</label>
              <input
                name="MfgYear"
                onChange={formik.handleChange}
                value={formik.values.MfgYear}
                className={
                  formik.touched.MfgYear && formik.errors.MfgYear ? "error" : ""
                }
              />
            </div>
            <div>
              <label htmlFor="Colour">Colour</label>
              <select
                name="Colour"
                onChange={formik.handleChange}
                value={formik.values.Colour}
                onFocus={() => getColor()}
                className={
                  formik.touched.Colour && formik.errors.Colour ? "error" : ""
                }
              >
                <option></option>
                {lovOption(color)}
              </select>
            </div>
          </div>
          <div className="double-input">
            <div>
              <label htmlFor="PlaceOfRegn">Place of registration</label>
              <select
                name="PlaceOfRegn"
                onFocus={() => getLocation()}
                onChange={formik.handleChange}
                value={formik.values.PlaceOfRegn}
                className={
                  formik.touched.PlaceOfRegn && formik.errors.PlaceOfRegn
                    ? "error"
                    : ""
                }
              >
                <option></option>
                {lovOption(location)}
              </select>
            </div>
            <div>
              <label htmlFor="CC">CC</label>
              <select
                name="CC"
                onFocus={() => getCC()}
                onChange={formik.handleChange}
                value={formik.values.CC}
                className={formik.touched.CC && formik.errors.CC ? "error" : ""}
              >
                <option></option>
                {lovOption(cC)}
              </select>
            </div>
            {/* <div>
              <label htmlFor="CoverCode">Cover Code</label>
              <select
                name="CoverCode"
                onFocus={() => getCoverCode()}
                onChange={formik.handleChange}
                value={formik.values.CoverCode}
                className={
                  formik.touched.CoverCode && formik.errors.CoverCode
                    ? "error"
                    : ""
                }
              >
                <option></option>
                {lovOption(coverCode)}
              </select>
            </div> */}
          </div>
          <div className="triple-input">
            {!formik.values.TypeOfcover.includes(2) &&
              !formik.values.TypeOfcover.includes(3) && (
                <div>
                  <label htmlFor="VehicleValue">Sum Insured</label>
                  <input
                    name="VehicleValue"
                    onChange={formik.handleChange}
                    value={formik.values.VehicleValue}
                    className={
                      formik.touched.VehicleValue && formik.errors.VehicleValue
                        ? "error"
                        : ""
                    }
                  />
                </div>
              )}
            {!formik.values.TypeOfcover.includes(2) &&
              !formik.values.TypeOfcover.includes(3) && (
                <div>
                  <label htmlFor="Rate">Rate %</label>
                  <input
                    name="Rate"
                    onChange={formik.handleChange}
                    value={formik.values.Rate}
                    className={
                      formik.touched.Rate && formik.errors.Rate ? "error" : ""
                    }
                  />
                </div>
              )}
            {/* ============================================================================================================================ */}
            {/* ============================================================================================================================ */}
            {(formik.values.TypeOfcover.includes(2) ||
              formik.values.TypeOfcover.includes(3)) && (
              <div>
                <label htmlFor="Rate">Premium</label>
                <input
                  name="Premium"
                  onChange={formik.handleChange}
                  value={formik.values.Premium}
                  className={
                    formik.touched.Premium && formik.errors.Premium
                      ? "error"
                      : ""
                  }
                />
              </div>
            )}
            {/* ============================================================================================================================ */}
            {/* ============================================================================================================================ */}
            {formik.values.VehicleValue > 0 &&
              duration &&
              formik.values.Rate > 0 && (
                <div className="premium-info">
                  <p>{`Total Premium: ₦${numFormat(
                    (formik.values.Rate / 100) * formik.values.VehicleValue
                  )}`}</p>
                  <p>{`Payable Premium: ₦${numFormat(
                    ((formik.values.Rate / 100) * formik.values.VehicleValue) /
                      duration
                  )}`}</p>
                </div>
              )}
          </div>
          <button type="submit" className="btn-next">
            {isAdding ? "Adding Vehicle..." : "ADD"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default VehicleInformation;
