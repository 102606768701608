import React from "react";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import CustomerInformation from "./CustomerInformation";
import { deleteIndividual, getIndividuals } from "../services";
import EditCustomer from "../Components/EditCustomer";
import {
  exportCsv,
  noDataMessage,
  notify,
  paginate,
} from "../Components/CommonActions";
import CryptoJS from "crypto-js";
import { IndividualCreditNote } from "../Components/IndividualCreditNote";

const IndividualsPage = () => {
  const [isCustomer, setIsCustomer] = useState(false);
  const [individualOptions, setIndividualOptions] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [editId, setEditId] = useState(0);
  const [search, setSearch] = useState("");
  const [currentCustomer, setCurrentCustomer] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    gender: "",
    dateOfBirth: "",
    email: "",
    occupation: "",
    nationality: "",
    address: "",
    brokerCode: "",
    rate: "",
  });
  const queryClient = useQueryClient();

  const headers = [
    { label: "First Name", key: "firstName" },
    { label: "Middle Name", key: "middleName" },
    { label: "Last Name", key: "lastName" },
    { label: "Occupation", key: "occupation" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
  ];

  const { data, status, refetch } = useQuery(
    "individualspage",
    getIndividuals,
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  let navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;

  const searchData = search
    ? data.filter((item) =>
        (
          item.firstName.toLowerCase() +
          item.lastName.toLowerCase() +
          item.middleName.toLowerCase() +
          item.occupation.toLowerCase() +
          item.email.toLowerCase() +
          item.phone.toLowerCase()
        ).includes(search.toLowerCase())
      )
    : data;

  const deleteIndividuals = async () => {
    deleteIndividual(individualOptions)
      .then((res) => {
        queryClient.refetchQueries();
        notify("success", "Success", res);
      })
      .catch((error) => alert.error(error));
    queryClient.refetchQueries();
  };

  const displayIndividuals =
    searchData &&
    searchData
      .slice(pagesVisited, pagesVisited + usersPerPage)
      .map((individual) => {
        return (
          <tr
            key={individual.id}
            onClick={() => {
              sessionStorage.setItem(process.env.REACT_APP_ID, individual.id);
              sessionStorage.setItem(
                process.env.REACT_APP_CUSTOMER_NAME,
                `${individual.firstName} ${individual.lastName}`
              );
              setCurrentCustomer({
                firstName: individual.firstName,
                middleName: individual.middleName,
                lastName: individual.lastName,
                phone: individual.phone,
                gender: individual.gender,
                dateOfBirth: individual.dateOfBirth,
                email: individual.email,
                occupation: individual.occupation,
                nationality: individual.nationality,
                address: individual.address,
                rate: individual.rate,
              });
            }}
          >
            <td>{individual.firstName}</td>
            <td>{individual.middleName}</td>
            <td>{individual.lastName}</td>
            <td>{individual.occupation}</td>
            <td>{individual.email}</td>
            <td className="phone-td">{individual.phone}</td>
            <td
              className="dots"
              onClick={() => {
                individualOptions === individual.id
                  ? setIndividualOptions(0)
                  : setIndividualOptions(individual.id);
              }}
            >
              ...
            </td>
            <td
              className={
                individual.id === individualOptions
                  ? "vehicle-options show-vehicle-options"
                  : "vehicle-options"
              }
            >
              <ul>
                <li
                  onClick={() => {
                    setIsEdit(true);
                    setEditId(individual.id);
                  }}
                >
                  Edit customer
                </li>
                <li
                  onClick={() => {
                    const encryptedId = CryptoJS.AES.encrypt(
                      JSON.stringify(individual.id),
                      process.env.REACT_APP_CRYPTO_SECRET
                    ).toString();

                    const excludedChars = /[/]/g;
                    const filteredString = encryptedId.replace(
                      excludedChars,
                      "0GCgC2zO3Der7YlxA67s"
                    );

                    navigate(`/individual/${filteredString}`);
                    console.log(filteredString);
                  }}
                >
                  View vehicles
                </li>
                <li
                  onClick={() => {
                    setIsDelete(true);
                  }}
                >
                  Delete Client
                </li>
              </ul>
            </td>
          </tr>
        );
      });

  const pageCount = Math.ceil(data && data.length / usersPerPage);
  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <div className="dashboard-container">
      <div
        className={
          isDelete
            ? "customer-container log-out-container show-customer-container"
            : "customer-container"
        }
      >
        <div className="customer-information log-out">
          <h1>CONFIRM DELETE</h1>
          <p>Are you sure you want to Delete this Customer?</p>
          <div>
            <button
              onClick={() => {
                deleteIndividuals();
                setIsDelete(false);
                queryClient.refetchQueries();
              }}
            >
              Confirm
            </button>
            <button onClick={() => setIsDelete(false)}>Cancel</button>
          </div>
        </div>
      </div>
      <div className="dashboard">
        <div className="dashboard-header">
          <div>
            <h1>Individuals</h1>
            <p>{`${data && searchData.length} individual clients`}</p>
          </div>
          <button
            onClick={() => {
              setIsCustomer(!isCustomer);
              setIsEdit(false);
            }}
          >
            + Add Individual
          </button>
        </div>
        {data && data.length < 1 ? (
          noDataMessage("Individual Client", "Add Individual")
        ) : (
          <>
            {data && (
              <div className="search-container">
                <input
                  placeholder="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {exportCsv(
                  data,
                  headers,
                  `Individual clients ${new Date(Date.now())}`
                )}
              </div>
            )}
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Middle Name</th>
                  <th>Last Name</th>
                  <th>Occupation</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th className="dots">...</th>
                </tr>
              </thead>
              <tbody>
                {status === "loading" ? (
                  <div>loading</div>
                ) : status === "error" ? (
                  <div>Error</div>
                ) : (
                  <>{displayIndividuals}</>
                )}
              </tbody>
            </table>
          </>
        )}
        {searchData &&
          searchData.length > 0 &&
          paginate(pageCount, handlePageClick)}
      </div>
      <CustomerInformation
        isCustomer={isCustomer}
        setIsCustomer={setIsCustomer}
        editId={editId}
        setEditId={setEditId}
        queryClient={queryClient}
      />
      {isEdit && (
        <EditCustomer
          isCustomer={isCustomer}
          setIsCustomer={setIsCustomer}
          refetch={refetch}
          editId={editId}
          setEditId={setEditId}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          currentCustomer={currentCustomer}
          queryClient={queryClient}
        />
      )}
    </div>
  );
};
export default IndividualsPage;
