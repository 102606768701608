import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import imageUpload from "../Photos/upload-image.png";
import check from "../Photos/check-mark.png";
import { useQuery } from "react-query";
import { IoCloseOutline } from "react-icons/io5";
import * as XLSX from "xlsx";
import { getMyCorporates, newFleet, newFleetVehicles } from "../services";
import { AuthContext } from "../helpers/AuthContext";
import { notify, numFormat } from "./CommonActions";
import { useEffect } from "react";
import { FcCancel } from "react-icons/fc";

const AddFleet = ({ id, isActive, setIsActive, refetch }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [duration, setDuration] = useState("");
  const [items, setItems] = useState([]);
  const [select, setSelect] = useState("");
  const [policyNo, setPolicyNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [duplicateReg, setDuplicateReg] = useState([]);
  const { navFileUpload, setNavFileUpload, userData } = useContext(AuthContext);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((d) => {
      setItems(d);
    });
  };

  let brokerId;
  let currentYear;

  currentYear = new Date().getFullYear();
  brokerId = Math.random().toString(36).substr(2, 6);

  useEffect(() => {
    setPolicyNo(Math.random().toString(36).substr(2, 5));
  }, []);

  const { data, status } = useQuery("corporates", getMyCorporates, {
    onError: (error) => {
      console.log(error);
    },
  });

  const corporateName = (array) => {
    let corporateName;
    corporateName =
      array &&
      array.map((datas) => {
        return <option key={datas.id}>{datas.name}</option>;
      });
    return corporateName;
  };

  let client = data && data.find((client) => client.name === select);
  let agreedRate = client && client.agreedRate;
  let corporateId = client && client.id;

  let file = items;
  file = file.map((dt) => ({
    ...dt,
    // corporateId: client && client.id,
    status: "Not Paid",
    fleetCode: `FLT${brokerId}`,
    policyNo: `MF/AUTO/${currentYear}/${policyNo}`,
  }));

  //Other Cover Start
  let otherCover;
  let sumInsured;
  let sum;

  otherCover =
    file &&
    file.filter((vehicle) => !vehicle.TypeOfcover.includes("Third Party"));

  sum =
    otherCover &&
    otherCover.reduce(function (prev, current) {
      return prev + +current.VehicleValue;
    }, 0);
  sumInsured =
    otherCover &&
    otherCover.reduce(function (prev, current) {
      return prev + +current.sumInsured;
    }, 0);

  //Other Cover End

  //THIRD PARTY START
  let allThirdPartyVehicles;
  let thirdPartyVehicles;
  let thirdPartyPremium;
  let commercialPremium;
  let privatePremium;
  let motorcyclePremium;
  let thirdPartyTotal;
  let commercialTotal;
  let privateTotal;
  let motorcycleTotal;
  let commercialVehicles;
  let privateVehicles;
  let motorcycles;

  allThirdPartyVehicles =
    file &&
    file.filter((vehicle) => vehicle.TypeOfcover.includes("Third Party"));

  thirdPartyVehicles =
    file &&
    file.filter(
      (vehicle) =>
        vehicle.TypeOfcover.includes("Third Party") &&
        vehicle.UsageType !== "COMMERCIAL" &&
        vehicle.UsageType !== "PRIVATE" &&
        vehicle.UsageType !== "PRIVATE PMC"
    );

  commercialVehicles =
    allThirdPartyVehicles &&
    allThirdPartyVehicles.filter(
      (vehicle) => vehicle.UsageType === "COMMERCIAL"
    );
  privateVehicles =
    allThirdPartyVehicles &&
    allThirdPartyVehicles.filter((vehicle) => vehicle.UsageType === "PRIVATE");
  motorcycles =
    allThirdPartyVehicles &&
    allThirdPartyVehicles.filter(
      (vehicle) => vehicle.UsageType === "PRIVATE PMC"
    );

  privatePremium = client && client.privatePremium;
  motorcyclePremium = client && client.motorcyclePremium;
  commercialPremium = client && client.commercialPremium;
  thirdPartyPremium = client && client.thirdPartyPremium;

  thirdPartyTotal =
    thirdPartyVehicles &&
    thirdPartyVehicles.length * (client && client.thirdPartyPremium);
  commercialTotal =
    commercialVehicles &&
    commercialVehicles.length * (client && client.commercialPremium);
  privateTotal =
    privateVehicles &&
    privateVehicles.length * (client && client.privatePremium);
  motorcycleTotal =
    motorcycles && motorcycles.length * (client && client.motorcyclePremium);

  //THIRD PARTY END

  const newVehicle = async () => {
    setIsUploading(true);
    await newFleetVehicles({ upload: file })
      .then(async (res) => {
        const { status, message, duplicates } = res;
        if (status === "success") {
          newFleet({
            fleetCode: `FLT${brokerId}`,
            status: "Not Paid",
            totalNum: items && items.length,
            totalSumInsured: sumInsured,
            totalPremium: (agreedRate / 100) * sumInsured,
            brokerId: userData && userData.brokerId,
            duration: duration,
            policyNo: `MF/AUTO/${currentYear}/${policyNo}`,
            corporateId: corporateId,
            rate: agreedRate,
            paidNum: 0,
            // totalThirdPartyNum: thirdPartyVehicles && thirdPartyVehicles.length,
            // agreedThirdPartyPremium: thirdPartyPremium && thirdPartyPremium,
            // totalThirdPartyAmount: thirdPartyTotal && thirdPartyTotal,
            // --------------------------------------------------------------------
            totalCommercialNum: commercialVehicles && commercialVehicles.length,
            agreedCommercialPremium: commercialPremium && commercialPremium,
            totalCommercialAmount: commercialTotal && commercialTotal,
            // --------------------------------------------------------------------
            totalPrivateNum: privateVehicles && privateVehicles.length,
            agreedPrivatePremium: privatePremium && privatePremium,
            totalPrivateAmount: privateTotal && privateTotal,
            // --------------------------------------------------------------------
            totalMotorcycleNum: motorcycles && motorcycles.length,
            agreedMotorcyclePremium: motorcyclePremium && motorcyclePremium,
            totalMotorcycleAmount: motorcycleTotal && motorcycleTotal,
            // --------------------------------------------------------------------
            totalAmount:
              ((agreedRate / 100) * sumInsured) / duration +
              // thirdPartyTotal +
              (commercialTotal + privateTotal + motorcycleTotal),
          });
          setIsSuccess(true);
          setLoading(false);
          refetch();
          setIsUploading(false);
        } else {
          setDuplicateReg(duplicates);
          setIsDuplicate(true);
        }
      })
      .catch((error) => console.log(error));
    sessionStorage.setItem(
      process.env.REACT_APP_FLEET_CODE_CREATION,
      `FLT${brokerId}`
    );
    sessionStorage.setItem(
      process.env.REACT_APP_UPDATE_CLIENT_ID,
      client && client.name
    );
  };

  return (
    <div
      className={
        isActive
          ? "customer-container show-customer-container"
          : "customer-container"
      }
    >
      <div
        className={
          isSuccess ? "success-modal show-success-modal" : "success-modal"
        }
      >
        <div className="modal">
          <img src={check} alt="checkmark" className="checkmark" />
          <p>
            Your information has been successfully submitted with policy number:
          </p>
          <h4>{`MF/AUTO/${currentYear}/${policyNo.toUpperCase()}`}</h4>
          <Link
            className="btn-next"
            onClick={() => {
              setNavFileUpload(true);
              setIsActive(false);
              setIsSuccess(false);
              sessionStorage.setItem(
                process.env.REACT_APP_FILE_UPLOAD_USER,
                select
              );
            }}
          >
            PROCEED TO CHECKOUT
          </Link>
        </div>
      </div>
      <div
        className={
          isDuplicate ? "success-modal show-success-modal" : "success-modal"
        }
      >
        <div className="modal">
          <FcCancel className="alert-cancel" />
          <p>
            The following vehicle Registration Numbers are duplicated on the
            uploaded file:
          </p>
          <div className="duplicate-container">
            {duplicateReg.length > 0 &&
              duplicateReg.map((reg) => {
                return <span key={reg}>{reg}</span>;
              })}
          </div>
          <p>
            Note that vehicle Registration Numbers should be unique, please
            review and re-upload.
          </p>
          <Link
            className="btn-next"
            style={{ backgroundColor: "red" }}
            onClick={() => {
              window.location.reload(false);
            }}
          >
            Close
          </Link>
        </div>
      </div>
      <div
        className={
          items.length > 0
            ? "hide-upload customer-information fleet-container"
            : "customer-information fleet-container"
        }
      >
        <div className="customer-information-header">
          <h1 className="header">Add fleet</h1>
          <a
            href={`${process.env.REACT_APP_API_URL}file/Assets/Upload_Template.xlsx`}
            className="download-template export-btn"
          >
            Download Template
          </a>
          <IoCloseOutline
            className="btn-close"
            onClick={() => setIsActive(!isActive)}
          />
        </div>
        <div className="fleet-frequency">
          <label>Select Customer</label>
          <select
            name="select"
            value={select}
            onChange={(e) => {
              setSelect(e.target.value);
            }}
          >
            <option></option>
            {corporateName(data)}
          </select>
        </div>
        <div className="fleet-frequency">
          <label>Frequency of payment</label>
          <select
            placeholder="DURATION"
            name="Duration"
            onChange={(e) => setDuration(e.target.value)}
            // value={formik.values.VehExitDt}
            type="date"
          >
            <option></option>
            <option value="4">Quarterly</option>
            <option value="2">Bi-annual</option>
            <option value="1">One off(yearly)</option>
          </select>
        </div>

        <div className="upload-container">
          <img src={imageUpload} alt="upload" />
          <h2>
            {items.length > 0
              ? "FILE SAVED SUCCESSFULLY"
              : "CLICK ANYWHERE TO BROWSE FILES"}
          </h2>
          <input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            className="upload-file"
            onChange={(e) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
          />
        </div>

        {loading && <h1>UPLOADING YOUR FILE...</h1>}
      </div>
      {items.length > 0 && (
        <div className="ft-info customer-information">
          <div className="customer-information-header">
            <h1 className="header">FLEET DETAILS</h1>
            <IoCloseOutline
              className="btn-close"
              onClick={async () => {
                window.location.reload(false);
              }}
            />
          </div>
          <div className="payout-top">
            <div>
              <span>Frequency</span>
              <p>
                {duration === "4"
                  ? "Quarterly"
                  : duration === "2"
                  ? "Bi-annual"
                  : "Yearly(One-off)"}
              </p>
            </div>
            <div>
              <span>NO. OF VEHICLES</span>
              <p>{otherCover && otherCover.length}</p>
            </div>
            <div>
              <span>Rate</span>
              <p>{agreedRate}%</p>
            </div>
          </div>
          <div className="payout-bottom ft-bottom">
            <div>
              <span>Sum Insured</span>
              <p>{numFormat(sumInsured)}</p>
            </div>
            <div>
              <span>Premium</span>
              <p>{numFormat((agreedRate / 100) * sumInsured)}</p>
            </div>
          </div>
          <div className="third-party-header">
            <h5>Third Party Details</h5>
          </div>
          {/* <div className="third-party-section">
            <div className="payout-top">
              <div>
                <span>No of vehicles</span>
                <p>{thirdPartyVehicles && thirdPartyVehicles.length}</p>
              </div>
              <div className="pay-div">
                <span>Agreed Premium</span>
                <p>{numFormat(thirdPartyPremium)}</p>
              </div>
              <div className="pay-div">
                <span>Total</span>
                <p>
                  {numFormat(
                    thirdPartyVehicles &&
                      thirdPartyVehicles.length * thirdPartyPremium
                  )}
                </p>
              </div>
            </div>
          </div> */}
          {/* COMMERCIAL VEHICLES=-=-=-=-=-=-=-=--=-=-=-=--=-=-=-=-=--=-=-=-=-=----=-=--=--==-=-==-=-=-=---=-=-=--=-=--=-=-=-=-=-=-=-=-=-=-=-=-= */}
          {commercialVehicles.length > 0 && (
            <>
              <div className="third-party-header">
                <h5>Commercial Vehicles:</h5>
              </div>
              <div className="third-party-section">
                <div className="payout-top">
                  <div>
                    <span>No of vehicles</span>
                    <p>{commercialVehicles && commercialVehicles.length}</p>
                  </div>
                  <div className="pay-div">
                    <span>Agreed Premium</span>
                    <p>{numFormat(commercialPremium)}</p>
                  </div>
                  <div className="pay-div">
                    <span>Total</span>
                    <p>
                      {numFormat(
                        commercialVehicles &&
                          commercialVehicles.length * commercialPremium
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* PRIVATE VEHICLES=-=-=-=-=-=-=-=--=-=-=-=--=-=-=-=-=--=-=-=-=-=----=-=--=--==-=-==-=-=-=---=-=-=--=-=--=-=-=-=-=-=-=-=-=-=-=-=-= */}
          {privateVehicles.length > 0 && (
            <>
              <div className="third-party-header">
                <h5>Private Vehicles:</h5>
              </div>
              <div className="third-party-section">
                <div className="payout-top">
                  <div>
                    <span>No of vehicles</span>
                    <p>{privateVehicles && privateVehicles.length}</p>
                  </div>
                  <div className="pay-div">
                    <span>Agreed Premium</span>
                    <p>{numFormat(privatePremium)}</p>
                  </div>
                  <div className="pay-div">
                    <span>Total</span>
                    <p>
                      {numFormat(
                        privateVehicles &&
                          privateVehicles.length * privatePremium
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* MOTORCYCLES=-=-=-=-=-=-=-=--=-=-=-=--=-=-=-=-=--=-=-=-=-=----=-=--=--==-=-==-=-=-=---=-=-=--=-=--=-=-=-=-=-=-=-=-=-=-=-=-= */}
          {motorcycles.length > 0 && (
            <>
              <div className="third-party-header">
                <h5>Motorcycles:</h5>
              </div>
              <div className="third-party-section">
                <div className="payout-top">
                  <div>
                    <span>No of vehicles</span>
                    <p>{motorcycles && motorcycles.length}</p>
                  </div>
                  <div className="pay-div">
                    <span>Agreed Premium</span>
                    <p>{numFormat(motorcyclePremium)}</p>
                  </div>
                  <div className="pay-div">
                    <span>Total</span>
                    <p>
                      {numFormat(
                        motorcycles && motorcycles.length * motorcyclePremium
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="amount-due">
            <p>Amount Due:</p>
            <h2>
              {numFormat(
                ((agreedRate / 100) * sumInsured) / duration +
                  // thirdPartyTotal +
                  (commercialTotal + privateTotal + motorcycleTotal)
              )}
            </h2>
          </div>
          <Link
            onClick={async () => {
              await newVehicle();
              setLoading(true);
            }}
            className="btn-next"
          >
            {isUploading ? "Uploading, please wait..." : "PROCEED"}
          </Link>
        </div>
      )}
    </div>
  );
};

export default AddFleet;
