import React from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteFleet,
  getFleets,
  getLovDetails,
  getMyCorporates,
  updateCertNum,
} from "../services";
import FleetUpload from "./FleetUpload";
import CertificateImage from "../Photos/CertificateImg.png";
import { format } from "date-fns";
import UpdateFleetPayment from "../Components/UpdateFleetPayment";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CorporateTransactionHistory } from "../Components/CorporateTransactionHistory";
import { noDataMessage, notify, paginate } from "../Components/CommonActions";
import { CiFilter } from "react-icons/ci";
import { FaFilter } from "react-icons/fa";
import CryptoJS from "crypto-js";

const CorporatesFleets = () => {
  const [isActive, setIsActive] = useState(false);
  const [isCertificate, setIsCertificate] = useState(false);
  const [isUpdateActive, setIsUpdateActive] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteCode, setDeleteCode] = useState(false);
  const [activeFleetCode, setActiveFleetCode] = useState("");
  const [activeFleets, setActiveFleets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fleetOptions, setFleetOptions] = useState(0);
  const [currentStatus, setCurrentStatus] = useState("all");
  const [isHistory, setIsHistory] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  let { id } = useParams();

  const unfilteredId = id.replace(/0GCgC2zO3Der7YlxA67s/g, "/");

  const { data, status, isSuccess, refetch } = useQuery(
    "fleets",
    () =>
      getFleets(
        CryptoJS.AES.decrypt(
          unfilteredId,
          process.env.REACT_APP_CRYPTO_SECRET
        ).toString(CryptoJS.enc.Utf8)
      ),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  let certificateFleetName = sessionStorage.getItem(
    process.env.REACT_APP_CORPORATE_FLEET_NAME
  );

  const getActiveFleets = async () => {
    let code = sessionStorage.getItem(process.env.REACT_APP_CODE);
    setIsLoading(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}vehicle/get-fleet-vehicles/${
          code && code
        }`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const fleets = await res.json();
      setIsLoading(false);
      setActiveFleets(fleets);
      console.log("generate done");
    } catch (error) {
      console.log(error);
    }
  };

  let navigate = useNavigate();

  const deleteFleets = async () => {
    deleteFleet(deleteCode)
      .then((res) => notify("success", "Success", res))
      .catch((error) => notify("danger", "Error", "Something went wrong"));
    // alert.success("Vehicle deleted");
    refetch();
  };

  const { data: myDetail } = useQuery("cor-det", getLovDetails);

  const getInfo = (Item, type) => {
    let make;
    let makeCode;
    if (myDetail) {
      make = myDetail.find(
        (code) => code.pc_code === Item && code.pc_type === type
      );
      makeCode = make && make.pc_desc;
    }
    return makeCode;
  };

  const filteredData =
    currentStatus === "all"
      ? data
      : currentStatus
      ? data.filter(
          (status) =>
            status.status.toLowerCase() === currentStatus.toLowerCase()
        )
      : data;

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;

  const displayFleets =
    filteredData &&
    filteredData
      .slice(pagesVisited, pagesVisited + usersPerPage)
      .map((fleet) => {
        return (
          <tr key={fleet.id}>
            <td className="heavy">{fleet && fleet.fleetCode.toUpperCase()}</td>
            <td className="heavy">{fleet && fleet.policyNo.toUpperCase()}</td>
            <td>{fleet.totalNum}</td>
            {/* <td className="phone-td">{fleet.totalSumInsured}</td>
            <td>{fleet.totalPremium}</td> */}
            <td>
              {fleet.duration === "4"
                ? "Quarterly"
                : fleet.duration === "2"
                ? "Bi-annual"
                : "Yearly(One-off)"}
            </td>
            <td>{fleet.rate}%</td>
            <td>{format(new Date(fleet.createdAt), "MM/dd/yyyy")}</td>
            <td
              className={
                fleet.status === "Not Paid"
                  ? "inactive"
                  : fleet.status === "Declined"
                  ? "declined"
                  : "activated"
              }
            >
              <p>
                {fleet.status === "Not Paid"
                  ? "Not Approved"
                  : fleet.status === "Paid"
                  ? "Approved"
                  : "Declined"}
              </p>
            </td>
            <td
              className="dots"
              onClick={() => {
                setActiveFleetCode(fleet.fleetCode);
                fleetOptions === fleet.id
                  ? setFleetOptions(0)
                  : setFleetOptions(fleet.id);
                // setFleetOptions(fleet.id);
                sessionStorage.setItem(
                  process.env.REACT_APP_CODE,
                  fleet.fleetCode
                );

                getActiveFleets();
              }}
            >
              ...
            </td>
            <td
              className={
                fleet.id === fleetOptions
                  ? "vehicle-options show-vehicle-options"
                  : "vehicle-options"
              }
            >
              <ul>
                <li
                  onClick={() => {
                    navigate(
                      `/corporates/${fleet.id}/fleet/${fleet.fleetCode}`
                    );
                  }}
                  // sessionStorage.setItem("id", individual.id)
                >
                  View Vehicles
                </li>

                {fleet.status === "Not Paid" && (
                  <li
                    onClick={() => {
                      setIsCertificate(false);
                      setIsDelete(true);
                      setDeleteCode(fleet.fleetCode);
                    }}
                  >
                    Delete Fleet
                  </li>
                )}
                {fleet.status === "Paid" && (
                  <>
                    <li
                      onClick={() => {
                        setIsCertificate(!isCertificate);
                      }}
                    >
                      Generate Certificate
                    </li>
                    {isCertificate && (
                      <PDFDownloadLink
                        document={
                          <Document>
                            {activeFleets &&
                              activeFleets.map((item) => {
                                return (
                                  <Page
                                    size="A4"
                                    style={styles.page}
                                    key={item.id}
                                  >
                                    <Text style={styles.text}>
                                      {certificateFleetName}
                                    </Text>
                                    <Text style={styles.make}>
                                      {" "}
                                      {`Make:     ${getInfo(
                                        item.Make,
                                        "MOT_VEH_MAKE"
                                      )}`}
                                    </Text>
                                    <Text style={styles.certNo}>
                                      {item.CertNo.toUpperCase()}
                                    </Text>
                                    <Text style={styles.policyNo}>
                                      {item.policyNo.toUpperCase()}
                                    </Text>
                                    <Text style={styles.coverType}>
                                      {getInfo(item.TypeOfcover, "COVER_TYPE")}
                                    </Text>
                                    <Text style={styles.regNo}>
                                      {item.RegNo}
                                    </Text>
                                    <Text style={styles.entryDate}>
                                      {format(
                                        new Date(item.VehEntryDt),
                                        "MM/dd/yyyy"
                                      )}
                                    </Text>
                                    <Text style={styles.exitDate}>
                                      {format(
                                        new Date(item.VehExitDt),
                                        "MM/dd/yyyy"
                                      )}
                                    </Text>
                                    <Image
                                      src={CertificateImage}
                                      style={styles.image}
                                    />
                                  </Page>
                                );
                              })}
                          </Document>
                        }
                        fileName="certificate"
                      >
                        {({ loading }) =>
                          loading || isLoading ? (
                            <button>please wait...</button>
                          ) : (
                            <button>Download</button>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                  </>
                )}
                {/* {fleet.duration !== fleet.paidNum && ( */}
                {fleet.status !== "Paid" && (
                  <li
                    onClick={() => {
                      setIsCertificate(false);
                      setIsUpdateActive(true);
                      const fleetUpdateDetails = {
                        NumOfVehicles: fleet.totalNum,
                        TotalSumInsured: fleet.totalSumInsured,
                        TotalPremium: fleet.totalPremium,
                        FleetCode: fleet.fleetCode,
                        duration: fleet.duration,
                        rate: fleet.rate,
                        totalAmount: fleet.totalAmount,
                        totalPremium: fleet.totalPremium,
                        agreedThirdPartyPremium: fleet.agreedThirdPartyPremium,
                        totalThirdPartyAmount: fleet.totalThirdPartyAmount,
                        totalThirdPartyNum: fleet.totalThirdPartyNum,
                        agreedCommercialPremium: fleet.agreedCommercialPremium,
                        totalCommercialAmount: fleet.totalCommercialAmount,
                        totalCommercialNum: fleet.totalCommercialNum,
                        agreedPrivatePremium: fleet.agreedPrivatePremium,
                        totalPrivateAmount: fleet.totalPrivateAmount,
                        totalPrivateNum: fleet.totalPrivateNum,
                        agreedMotorcyclePremium: fleet.agreedMotorcyclePremium,
                        totalMotorcycleAmount: fleet.totalMotorcycleAmount,
                        totalMotorcycleNum: fleet.totalMotorcycleNum,
                      };
                      const newDetails = JSON.stringify(fleetUpdateDetails);

                      status === "success" &&
                        sessionStorage.setItem(
                          process.env.REACT_APP_UPDATE_FLEET_DETAILS,
                          newDetails
                        );
                      sessionStorage.setItem(
                        process.env.REACT_APP_IS_FLEET_UPDATE,
                        true
                      );
                    }}
                  >
                    Make Payment
                  </li>
                )}
                {/* <li
                  onClick={() => {
                    setIsHistory(true);
                  }}
                >
                  Transaction history
                </li> */}
              </ul>
            </td>
          </tr>
        );
      });

  const pageCount = Math.ceil(
    filteredData && filteredData.length / usersPerPage
  );
  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="dashboard-container">
      <div
        className={
          isDelete
            ? "customer-container log-out-container show-customer-container"
            : "customer-container"
        }
      >
        <div className="customer-information log-out">
          <h1>CONFIRM DELETE</h1>
          <p>Are you sure you want to Delete this fleet?</p>
          <div>
            <Link
              onClick={() => {
                deleteFleets();
                setIsDelete(false);
                refetch();
              }}
            >
              Confirm
            </Link>
            <button onClick={() => setIsDelete(false)}>Cancel</button>
          </div>
        </div>
      </div>
      <div className="dashboard">
        <div className="dashboard-header">
          <div>
            <h1>Corporates Fleets</h1>
            <p>{`${isSuccess && data.length} corporate fleets`}</p>
          </div>
          <button
            onClick={() => {
              setIsActive(!isActive);
              sessionStorage.setItem(
                process.env.REACT_APP_IS_FLEET_UPDATE,
                false
              );
            }}
          >
            + Add Fleet
          </button>
        </div>
        <div className="filter" onClick={() => setIsFilter(!isFilter)}>
          <p>
            <FaFilter className="filter-svg" />{" "}
            {currentStatus === "all"
              ? `Filter by status...`
              : `${currentStatus} Fleets`}
          </p>
          <div
            className={
              isFilter ? "filter-list show-filter-list" : "filter-list"
            }
          >
            <button onClick={() => setCurrentStatus("all")}>All</button>
            <button onClick={() => setCurrentStatus("Paid")}>Paid</button>
            <button onClick={() => setCurrentStatus("not Paid")}>
              Not Paid
            </button>
            <button onClick={() => setCurrentStatus("Declined")}>
              Declined
            </button>
          </div>
        </div>
        {filteredData && filteredData.length < 1 ? (
          noDataMessage("Fleet", "Add Fleet")
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Fleet Code</th>
                  <th>Policy Number</th>
                  <th>Number of Cars</th>
                  {/* <th>Sum Insured</th>
              <th>Premium</th> */}
                  <th>Frequency</th>
                  <th>Rate</th>
                  <th>Created At</th>
                  <th>Status</th>
                  <th className="dots">...</th>
                </tr>
              </thead>
              <tbody>
                {status === "loading" ? (
                  <div>loading</div>
                ) : status === "error" ? (
                  <div>Error</div>
                ) : (
                  <>{displayFleets}</>
                )}
              </tbody>
            </table>
          </>
        )}
        {paginate(pageCount, handlePageClick)}
      </div>
      {isActive && (
        <FleetUpload
          id={CryptoJS.AES.decrypt(
            unfilteredId,
            process.env.REACT_APP_CRYPTO_SECRET
          ).toString(CryptoJS.enc.Utf8)}
          isActive={isActive}
          setIsActive={setIsActive}
          refetch={refetch}
        />
      )}
      {isUpdateActive && (
        <UpdateFleetPayment
          isActive={isUpdateActive}
          setIsActive={setIsUpdateActive}
        />
      )}
      {isHistory && (
        <CorporateTransactionHistory
          isHistory={isHistory}
          setIsHistory={setIsHistory}
        />
      )}
    </div>
  );
};
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    position: "relative",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
    color: "#000",
    innerWidth: "100%",
    outerWidth: "100%",
    height: "100%",
  },
  text: {
    marginTop: 230,
    marginLeft: 280,
    fontSize: "10px",
  },
  make: {
    position: "absolute",
    right: "50",
    top: "205",
    fontSize: "10px",
  },
  coverType: {
    position: "absolute",
    right: "46%",
    bottom: "30%",
    fontSize: "13px",
    fontWeight: "500",
  },
  regNo: {
    position: "absolute",
    left: "47%",
    top: "24.4%",
    fontSize: "10px",
  },
  certNo: {
    position: "absolute",
    left: "19.4%",
    top: "20.4%",
    fontSize: "10px",
  },
  policyNo: {
    position: "absolute",
    left: "55.4%",
    top: "20.4%",
    fontSize: "10px",
  },
  entryDate: {
    position: "absolute",
    left: "47%",
    top: "33.4%",
    fontSize: "10px",
  },
  exitDate: {
    position: "absolute",
    left: "47%",
    top: "37.6%",
    fontSize: "10px",
  },
});
export default CorporatesFleets;
