import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import imageUpload from "../Photos/upload-image.png";
import check from "../Photos/check-mark.png";
import { useQuery } from "react-query";
import { IoCloseOutline } from "react-icons/io5";
import * as XLSX from "xlsx";
import { getMyCorporates } from "../services";
import { AuthContext } from "../helpers/AuthContext";
import { UpdateFileUpload } from "./UpdateFileUpload";
import { numFormat } from "./CommonActions";

const UpdateFleetPayment = ({ id, isActive, setIsActive, refetch }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isUpload, setIsUpload] = useState(false);

  const { data, status } = useQuery("corporates", getMyCorporates, {
    onError: (error) => {
      console.log(error);
    },
  });

  let sum =
    items &&
    items.reduce(function (prev, current) {
      return prev + +current.VehicleValue;
    }, 0);
  let sumInsured =
    items &&
    items.reduce(function (prev, current) {
      return prev + +current.sumInsured;
    }, 0);

  const details = sessionStorage.getItem(
    process.env.REACT_APP_UPDATE_FLEET_DETAILS
  );
  const fleetDetails = JSON.parse(details);
  const rate = sessionStorage.getItem(process.env.REACT_APP_CORPORATE_RATE);

  return (
    <>
      {fleetDetails && (
        <div
          className={
            isActive
              ? "customer-container show-customer-container"
              : "customer-container"
          }
        >
          <div
            className={
              isSuccess ? "success-modal show-success-modal" : "success-modal"
            }
          >
            <div className="modal">
              <img src={check} alt="checkmark" className="checkmark" />
              <p>Your information has been successfully saved</p>
              {/* <h4>PROP/01/102/1231/00053</h4> */}
              <Link
                className="btn-next"
                onClick={() => {
                  setIsUpload(!isUpload);
                  // setIsActive(false);
                }}
              >
                PROCEED TO CHECKOUT
              </Link>
            </div>
          </div>
          {/* --------------------------------------------------------------------- */}

          <div className="ft-info customer-information">
            <div className="customer-information-header">
              <h1 className="header">FLEET DETAILS</h1>
              <IoCloseOutline
                className="btn-close"
                onClick={async () => {
                  window.location.reload(false);
                  setIsActive(!isActive);
                }}
              />
            </div>
            <div className="payout-top">
              <div>
                <span>Frequency</span>
                <p>
                  {fleetDetails && fleetDetails.duration === "4"
                    ? "Quarterly"
                    : fleetDetails.duration === "2"
                    ? "Bi-annual"
                    : "Yearly(One-off)"}
                </p>
              </div>
              <div>
                <span>No of vehicles</span>
                <p>
                  {fleetDetails &&
                    fleetDetails.NumOfVehicles -
                      // (fleetDetails && fleetDetails.totalThirdPartyNum) -
                      (fleetDetails && fleetDetails.totalCommercialNum) -
                      (fleetDetails && fleetDetails.totalPrivateNum) -
                      (fleetDetails && fleetDetails.totalMotorcycleNum)}
                </p>
              </div>
              <div>
                <span>Rate</span>
                <p>{rate} %</p>
              </div>
            </div>
            <div className="payout-bottom ft-bottom">
              <div>
                <span>Sum Insured</span>
                <p>{numFormat(fleetDetails && fleetDetails.TotalSumInsured)}</p>
              </div>
              <div>
                <span>Premium</span>
                <p>{numFormat(fleetDetails && fleetDetails.TotalPremium)}</p>
              </div>
            </div>

            <div className="third-party-header">
              <h5>Third Party Vehicles</h5>
            </div>
            {/* <div className="third-party-section">
              <div className="payout-top">
                <div>
                  <span>No of vehicles</span>
                  <p>{fleetDetails && fleetDetails.totalThirdPartyNum}</p>
                </div>
                <div className="pay-div">
                  <span>Agreed Premium</span>
                  <p>{fleetDetails && fleetDetails.agreedThirdPartyPremium}</p>
                </div>
                <div className="pay-div">
                  <span>Total</span>
                  <p>
                    {fleetDetails &&
                      fleetDetails.totalThirdPartyNum *
                        (fleetDetails && fleetDetails.agreedThirdPartyPremium)}
                  </p>
                </div>
              </div>
            </div> */}

            <div className="third-party-header">
              <h5>Commercial Vehicles:</h5>
            </div>
            <div className="third-party-section">
              <div className="payout-top">
                <div>
                  <span>No of vehicles</span>
                  <p>{fleetDetails && fleetDetails.totalCommercialNum}</p>
                </div>
                <div className="pay-div">
                  <span>Agreed Premium</span>
                  <p>{numFormat(fleetDetails && fleetDetails.agreedCommercialPremium)}</p>
                </div>
                <div className="pay-div">
                  <span>Total</span>
                  <p>
                    {numFormat(fleetDetails &&
                      fleetDetails.totalCommercialNum *
                        (fleetDetails && fleetDetails.agreedCommercialPremium))}
                  </p>
                </div>
              </div>
            </div>

            <div className="third-party-header">
              <h5>Private Vehicles:</h5>
            </div>
            <div className="third-party-section">
              <div className="payout-top">
                <div>
                  <span>No of vehicles</span>
                  <p>{fleetDetails && fleetDetails.totalPrivateNum}</p>
                </div>
                <div className="pay-div">
                  <span>Agreed Premium</span>
                  <p>{numFormat(fleetDetails && fleetDetails.agreedPrivatePremium)}</p>
                </div>
                <div className="pay-div">
                  <span>Total</span>
                  <p>
                    {numFormat(fleetDetails &&
                      fleetDetails.totalPrivateNum *
                        (fleetDetails && fleetDetails.agreedPrivatePremium))}
                  </p>
                </div>
              </div>
            </div>

            <div className="third-party-header">
              <h5>Motorcycles:</h5>
            </div>
            <div className="third-party-section">
              <div className="payout-top">
                <div>
                  <span>No of vehicles</span>
                  <p>{fleetDetails && fleetDetails.totalMotorcycleNum}</p>
                </div>
                <div className="pay-div">
                  <span>Agreed Premium</span>
                  <p>
                    {numFormat(
                      fleetDetails && fleetDetails.agreedMotorcyclePremium
                    )}
                  </p>
                </div>
                <div className="pay-div">
                  <span>Total</span>
                  <p>
                    {numFormat(
                      fleetDetails &&
                        fleetDetails.totalMotorcycleNum *
                          (fleetDetails && fleetDetails.agreedMotorcyclePremium)
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="amount-due">
              <p>Amount Due:</p>
              <h2>{numFormat(fleetDetails && fleetDetails.totalAmount)}</h2>
            </div>
            <Link
              onClick={async () => {
                setIsSuccess(true);
              }}
              className="btn-next"
            >
              PROCEED
            </Link>
          </div>

          {/* </div> */}
        </div>
      )}
      {isUpload && (
        <UpdateFileUpload isUpload={isUpload} setIsUpload={setIsUpload} />
      )}
    </>
  );
};

export default UpdateFleetPayment;
