import React, { useState, useContext } from "react";
import imageUpload from "../Photos/upload-image.png";
import axios from "axios";
import { IoCloseOutline } from "react-icons/io5";
import { AuthContext } from "../helpers/AuthContext";
import { Link } from "react-router-dom";

export const UpdateFileUpload = ({ isUpload, setIsUpload }) => {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [noteNumber, setNoteNumber] = useState("");
  const [noteDate, setNoteDate] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  let fleetCodeUpdate = sessionStorage.getItem(process.env.REACT_APP_CODE);

  let corporateId = sessionStorage.getItem(process.env.REACT_APP_CORPORATE_ID);
  let corporateFleetName = sessionStorage.getItem(
    process.env.REACT_APP_CORPORATE_FLEET_NAME
  );

  const uploadFile = async (e) => {
    const formData = new FormData();
    const file = e.target.files || e.dataTransfer.files;

    if (file && file.length < 1) {
      alert("please upload a file.");
      return;
    }
    setIsLoading(true);
    formData.append("file", file[0]);

    try {
      const fileResponse = await axios.post(
        "http://staging-upgrade.fbninsurance.co/api/file-upload",
        formData
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}upload/${
          userData && userData.brokerId
        }/${corporateId}/${
          fleetCodeUpdate && fleetCodeUpdate
        }/${corporateFleetName}/${
          userData.brokerName
        }/${noteNumber}/${noteDate}/corporate/null`,
        {
          body: fileResponse.data.data.url,
        }
      );

      setResponseStatus(response.data.status);
      setResponseMessage(response.data.message);

      setIsLoading(false);
      setIsLoaded(true);
    } catch (ex) {
      setIsLoading(false);
      setIsError(true);
      alert("something went wrong.");
    }
  };

  const today = new Date();
  const todayDate = today.toISOString().substr(0, 10);

  return (
    <div
      className={
        isUpload
          ? "customer-container show-customer-container"
          : "customer-container"
      }
    >
      <div className="customer-information fleet-container">
        <div className="customer-information-header">
          <h1 className="header">CREDIT NOTE UPLOAD</h1>
          <IoCloseOutline
            className="btn-close"
            onClick={() => window.location.reload(false)}
          />
        </div>
        <div className="double-input credit-note-input">
          <div className="input-container">
            <label>Credit note number</label>
            <input
              value={noteNumber}
              onChange={(e) => setNoteNumber(e.target.value)}
            />
          </div>
          <div className="input-container">
            <label>Credit note date</label>
            <input
              type="date"
              value={noteDate}
              onChange={(e) => setNoteDate(e.target.value)}
              min={todayDate}
            />
          </div>
        </div>
        <div className="upload-container">
          <img src={imageUpload} alt="upload" />
          {!isLoaded && !isLoading && !isError && (
            <>
              <h2>CLICK ANYWHERE TO UPLOAD CREDIT NOTE</h2>
              <p style={{ color: "red", fontSize: "0.6rem", marginTop: "7px" }}>
                *only jpg, jpeg, png and pdf formats*
              </p>
            </>
          )}
          {isLoading && !isLoaded && <h2>UPLOADING YOUR CREDIT NOTE...</h2>}
          {isError && (
            <div className="upload-notes">
              <h2>SOMETHING WENT WRONG</h2>
              <p>Please reload the page and try again.</p>
            </div>
          )}
          {isLoaded && (
            <div className="upload-notes">
              <h2>
                {responseStatus === "failed"
                  ? ""
                  : "CREDIT NOTE UPLOADED SUCCESSFULY"}
              </h2>
              <p>{responseMessage}</p>
            </div>
          )}

          <input
            type="file"
            onChange={uploadFile}
            className="upload-file"
            accept=".jpg, .jpeg, .png, .pdf"
          />
        </div>

        {/* {loading && <h1>UPLOADING YOUR FILE...</h1>} */}

        {isLoaded && (
          <Link
            to={"/corporates"}
            onClick={() => {
              setIsUpload(false);
              window.location.reload(false);
            }}
            className="btn-next"
          >
            CLICK TO GO HOME
          </Link>
        )}
      </div>
    </div>
  );
};
