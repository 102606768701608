import React, { useEffect, useState, useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import VehicleInformation from "./VehicleInformation";
import { format } from "date-fns";
import CertificateImage from "../Photos/CertificateImg.png";
import { IndividualTransactionHistory } from "../Components/IndividualTransactionHistory";
import { Page, Text, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  deleteVehicle,
  getIndividual,
  getIndividualInfo,
  getLovDetails,
} from "../services";
import {
  noDataMessage,
  notify,
  numFormat,
  paginate,
} from "../Components/CommonActions";
import { Vehicle } from "./Vehicle";
import { FaFilter } from "react-icons/fa";
import CryptoJS from "crypto-js";

export const Individuals = () => {
  const [isActive, setIsActive] = useState(false);
  const [vehicleOptions, setVehicleOptions] = useState(0);
  const { isPayment, setIsPayment } = useContext(AuthContext);
  const [isDelete, setIsDelete] = useState(false);
  const [isCertificate, setIsCertificate] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [search, setSearch] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("all");
  const [isHistory, setIsHistory] = useState(false);
  const [regNo, setRegNo] = useState(0);
  const queryClient = useQueryClient();
  const [isFilter, setIsFilter] = useState(false);

  let { id, individuals } = useParams();

  const unfilteredId = id.replace(/0GCgC2zO3Der7YlxA67s/g, "/");

  const { data, status, refetch, isSuccess } = useQuery(
    "individuals",
    () =>
      getIndividualInfo(
        CryptoJS.AES.decrypt(
          unfilteredId,
          process.env.REACT_APP_CRYPTO_SECRET
        ).toString(CryptoJS.enc.Utf8)
      ),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { data: myIndividual } = useQuery("ind", () => getIndividual(id), {
    onError: (error) => {
      console.log(error);
    },
  });

  const { data: myDetail } = useQuery("cor-det", getLovDetails, {
    onError: (error) => {
      console.log(error);
    },
  });

  const deleteVehicles = async () => {
    deleteVehicle(deleteId)
      .then((res) => {
        notify("success", "Success", "Vehicle deleted successfully");
      })
      .catch((error) => notify("danger", "Error", "Something went wrong"));
    // refetch();
  };

  let navigate = useNavigate();

  const getInfo = (Item, type) => {
    let make;
    let makeCode;
    if (myDetail) {
      make = myDetail.find(
        (code) => code.pc_code === Item && code.pc_type === type
      );
      makeCode = make && make.pc_desc;
    }
    return makeCode;
  };

  let customerName = sessionStorage.getItem(
    process.env.REACT_APP_CUSTOMER_NAME
  );

  const filteredData =
    vehicleStatus === "all"
      ? data
      : vehicleStatus
      ? data.filter(
          (status) =>
            status.status.toLowerCase() === vehicleStatus.toLowerCase()
        )
      : data;

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 8;
  const pagesVisited = pageNumber * usersPerPage;

  const searchData = search
    ? data.filter((item) =>
        (
          item.policyNo.toLowerCase() +
          getInfo(item.Make, "MOT_VEH_MAKE").toLowerCase() +
          getInfo(item.Model, "VEHI_MOD").toLowerCase() +
          item.RegNo.toLowerCase() +
          item.chasisNumber.toLowerCase() +
          item.status.toLowerCase() +
          item.VehicleValue.toLowerCase()
        ).includes(search.toLowerCase())
      )
    : data;

  const displayVehicles =
    searchData &&
    searchData
      .slice(pagesVisited, pagesVisited + usersPerPage)
      .map((vehicle) => {
        return (
          <tr
            key={vehicle.id}
            onClick={() => {
              // navigate(`/${individuals}/vehicle/${vehicle.id}`);
              sessionStorage.setItem(
                process.env.REACT_APP_VEHICLE_ID,
                vehicle.id
              );
            }}
          >
            <td style={{ fontWeight: 700 }}>
              {vehicle.policyNo.toUpperCase()}
            </td>
            <td>{getInfo(vehicle.Model, "VEHI_MOD")}</td>
            <td>{getInfo(vehicle.Make, "MOT_VEH_MAKE")}</td>
            <td>{vehicle.RegNo}</td>
            <td>{vehicle.chasisNumber}</td>
            {/* <td>
              {vehicle.VehicleValue === ""
                ? ""
                : numFormat(vehicle.VehicleValue)}
            </td> */}
            <td
              className={
                vehicle.status === "Not Paid"
                  ? "inactive"
                  : vehicle.status === "Declined"
                  ? "declined"
                  : "activated"
              }
            >
              <p>{vehicle.status}</p>
            </td>
            <td
              className="dots"
              onClick={() => {
                vehicleOptions === vehicle.id
                  ? setVehicleOptions(0)
                  : setVehicleOptions(vehicle.id);
                sessionStorage.setItem(
                  process.env.REACT_APP_CUR_REG_NO,
                  vehicle.RegNo
                );
              }}
            >
              ...
            </td>
            <td
              className={
                vehicle.id === vehicleOptions
                  ? "vehicle-options show-vehicle-options"
                  : "vehicle-options"
              }
            >
              <ul className="vehicle-list">
                <li
                  onClick={() => {
                    // navigate(`/${individuals}/vehicle/${vehicle.id}`);
                    setIsCertificate(false);
                    setVehicleDetails(true);
                    sessionStorage.setItem(
                      process.env.REACT_APP_VEHICLE_ID,
                      vehicle.id
                    );
                    sessionStorage.setItem(
                      process.env.REACT_APP_CORPORATE_PAYMENT,
                      false
                    );
                  }}
                >
                  View Details
                </li>
                {vehicle.status === "Paid" && (
                  <>
                    <li onClick={() => setIsCertificate(!isCertificate)}>
                      Generate Certificate
                    </li>
                    {isCertificate && (
                      <PDFDownloadLink
                        document={
                          <Document>
                            <Page size="A4" style={styles.page}>
                              <Text style={styles.text}>{customerName}</Text>
                              <Text style={styles.make}>
                                {" "}
                                {`Make:     ${getInfo(
                                  vehicle.Make,
                                  "MOT_VEH_MAKE"
                                )}`}
                              </Text>
                              <Text style={styles.certNo}>
                                {vehicle.CertNo}
                              </Text>
                              <Text style={styles.policyNo}>
                                {vehicle.policyNo.toUpperCase()}
                              </Text>
                              <Text style={styles.coverType}>
                                {getInfo(vehicle.TypeOfcover, "COVER_TYPE")}
                              </Text>
                              <Text style={styles.regNo}>{vehicle.RegNo}</Text>
                              <Text style={styles.entryDate}>
                                {format(
                                  new Date(vehicle.VehEntryDt),
                                  "MM/dd/yyyy"
                                )}
                              </Text>
                              <Text style={styles.exitDate}>
                                {format(
                                  new Date(vehicle.VehExitDt),
                                  "MM/dd/yyyy"
                                )}
                              </Text>
                              <Image
                                src={CertificateImage}
                                style={styles.image}
                              />
                            </Page>
                          </Document>
                        }
                        fileName="certificate"
                      >
                        {({ loading }) =>
                          loading ? (
                            <button>please wait...</button>
                          ) : (
                            <button>Download</button>
                          )
                        }
                      </PDFDownloadLink>
                    )}
                  </>
                )}
                {vehicle.duration !== vehicle.paidNum && (
                  <li
                    onClick={() => {
                      setIsCertificate(false);
                      setIsPayment(true);
                      sessionStorage.setItem(
                        process.env.REACT_APP_IS_UPDATE,
                        true
                      );
                      const transactionDetails = {
                        VehicleValue: vehicle.VehicleValue,
                        sumInsured: vehicle.VehicleValue,
                        duration: vehicle.duration,
                        totalPremium:
                          data && vehicle.duration === "1"
                            ? vehicle.totalPremium
                            : (vehicle.Rate / 100) * vehicle.VehicleValue,
                        payablePremium:
                          (data && vehicle.duration === "1"
                            ? vehicle.payablePremium
                            : (vehicle.Rate / 100) * vehicle.VehicleValue) /
                          vehicle.duration,
                        Rate:
                          data && vehicle.duration === "1" ? "" : vehicle.Rate,
                        RegNo: data && vehicle.RegNo,
                      };
                      const newDetails = JSON.stringify(transactionDetails);

                      status === "success" &&
                        sessionStorage.setItem(
                          process.env.REACT_APP_PLAY,
                          newDetails
                        );
                    }}
                  >
                    Make Payment
                  </li>
                )}
                {vehicle.status !== "Paid" && (
                  <li
                    onClick={() => {
                      setIsCertificate(false);
                      setIsDelete(true);
                      setDeleteId(vehicle.id);
                    }}
                  >
                    Delete
                  </li>
                )}

                {vehicle.status !== "Not Paid" && (
                  <li
                    onClick={() => {
                      setIsCertificate(false);
                      setIsHistory(true);
                    }}
                  >
                    Transaction history
                  </li>
                )}
              </ul>
            </td>
          </tr>
        );
      });

  const pageCount = Math.ceil(
    filteredData && filteredData.length / usersPerPage
  );
  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard">
        <div className="dashboard-header">
          <div>
            {myIndividual &&
              myIndividual.map((data) => {
                const { id, firstName, lastName } = data;
                return <h1 key={id}>{`${firstName} ${lastName}`}</h1>;
              })}
            {data && <p>{`${isSuccess && data.length} vehicles registered`}</p>}
          </div>
          <button onClick={() => setIsActive(!isActive)}>+ Add Vehicle</button>
        </div>

        {filteredData && filteredData.length < 1 ? (
          noDataMessage("Vehicle", "Add Vehicle")
        ) : (
          <>
            {data && (
              <div className="search-container">
                <input
                  placeholder="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                {/* <div className="filter" onClick={() => setIsFilter(!isFilter)}>
                <p>
                  <FaFilter className="filter-svg" />{" "}
                  {vehicleStatus === "all"
                    ? `Filter by status...`
                    : `${vehicleStatus} Vehicles`}
                </p>
                <div
                  className={
                    isFilter ? "filter-list show-filter-list" : "filter-list"
                  }
                >
                  <button onClick={() => setVehicleStatus("all")}>All</button>
                  <button onClick={() => setVehicleStatus("Paid")}>Paid</button>
                  <button onClick={() => setVehicleStatus("not Paid")}>
                    Not Paid
                  </button>
                </div>
              </div> */}
              </div>
            )}
            <div
              className={
                isDelete
                  ? "customer-container log-out-container show-customer-container"
                  : "customer-container"
              }
            >
              <div className="customer-information log-out">
                <h1>CONFIRM DELETE</h1>
                <p>Are you sure you want to Delete this vehicle?</p>
                <div>
                  <Link
                    onClick={() => {
                      deleteVehicles();
                      setIsDelete(false);
                      queryClient.refetchQueries();
                    }}
                  >
                    Confirm
                  </Link>
                  <button onClick={() => setIsDelete(false)}>Cancel</button>
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Policy Number</th>
                  <th>Model</th>
                  <th>Make</th>
                  <th>Registration No</th>
                  <th>Chasis Number</th>
                  {/* <th>Sum Insured</th> */}
                  <th>Status</th>
                  <th className="dots">...</th>
                  <th className="dots"></th>
                </tr>
              </thead>
              <tbody>
                {status === "loading" ? (
                  <div>loading...</div>
                ) : status === "error" ? (
                  <div>Error</div>
                ) : (
                  <>{displayVehicles}</>
                )}
              </tbody>
            </table>
          </>
        )}
        {paginate(pageCount, handlePageClick)}
        {isHistory && (
          <IndividualTransactionHistory
            isHistory={isHistory}
            setIsHistory={setIsHistory}
            regNo={regNo}
            setRegNo={setRegNo}
          />
        )}
      </div>
      <VehicleInformation
        id={CryptoJS.AES.decrypt(
          unfilteredId,
          process.env.REACT_APP_CRYPTO_SECRET
        ).toString(CryptoJS.enc.Utf8)}
        isActive={isActive}
        setIsActive={setIsActive}
        refetch={refetch}
        queryClient={queryClient}
      />
      {vehicleDetails && (
        <Vehicle
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
        />
      )}
    </div>
  );
};
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    position: "relative",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 10,
    color: "#000",
    innerWidth: "100%",
    outerWidth: "100%",
    height: "100%",
  },
  text: {
    marginTop: 230,
    marginLeft: 280,
    fontSize: "10px",
  },
  policyNo: {
    position: "absolute",
    left: "55.4%",
    top: "20.4%",
    fontSize: "10px",
  },
  certNo: {
    position: "absolute",
    left: "19.4%",
    top: "20.4%",
    fontSize: "10px",
  },
  make: {
    position: "absolute",
    right: "70",
    top: "205",
    fontSize: "10px",
  },
  coverType: {
    position: "absolute",
    right: "46%",
    bottom: "30%",
    fontSize: "13px",
    fontWeight: "500",
  },
  regNo: {
    position: "absolute",
    left: "47%",
    top: "24.4%",
    fontSize: "10px",
  },
  entryDate: {
    position: "absolute",
    left: "47%",
    top: "33.4%",
    fontSize: "10px",
  },
  exitDate: {
    position: "absolute",
    left: "47%",
    top: "37.6%",
    fontSize: "10px",
  },
});
export default Individuals;
