import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { useQuery } from "react-query";
import { getMyQuotations } from "../services";
import { format } from "date-fns";

export const IndividualTransactionHistory = ({
  isHistory,
  setIsHistory,
}) => {
  let curRegNo = sessionStorage.getItem(process.env.REACT_APP_CUR_REG_NO);
  const { data, status, refetch, isSuccess } = useQuery(
    "myQuotations",
    () => getMyQuotations(curRegNo && curRegNo),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return (
    <div
      className={
        isHistory
          ? "customer-container show-customer-container"
          : "customer-container"
      }
    >
      <div className="customer-information history-container">
        <div className="customer-information-header">
          <h1 className="header">Transaction History</h1>
          <IoCloseOutline
            className="btn-close"
            onClick={() => setIsHistory(!isHistory)}
          />
        </div>

        <table>
          <thead>
            <tr>
              <th>Reg No</th>
              <th>Sum Insured</th>
              <th>Rate %</th>
              <th>Premium</th>
              {/* <th>Frequency</th> */}
              <th>Payable Premium</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {status === "loading" ? (
              <div>loading</div>
            ) : status === "error" ? (
              <div>Error</div>
            ) : (
              data &&
              data.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.regNumber}</td>
                    <td>{item.SumInsured}</td>
                    <td>{item.Rate}</td>
                    <td>{item.Premium}</td>
                    {/* <td>{item.Duration}</td> */}
                    <td>{item.PayablePremium}</td>
                    <td>{item.Status}</td>
                    <td>{format(new Date(item.createdAt), "MM/dd/yyyy")}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
